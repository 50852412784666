import React,{useState} from 'react'
import { Container, Typography, Breadcrumbs, Link, Box, Stack, Grid,Pagination } from '@mui/material';
import { Helmet } from 'react-helmet';
import data from '../../constants/data';
import OurWork from './OurWork';
import BlueContainer from '../../components/common/BlueContainer';


const OurWorks = () => {
    
    const ourWorksData = data.ourwork;
    const [currentPage, setCurrentPage] = useState(1);
    const ourWorksPerPage = 10; 
    const totalOurWorks = Object.entries(ourWorksData.sub).length;

    const indexOfLastOurWork = currentPage * ourWorksPerPage;
    const indexOfFirstOurWork = indexOfLastOurWork - ourWorksPerPage;
    const sliceData = Object.entries(ourWorksData.sub).slice(indexOfFirstOurWork, indexOfLastOurWork);

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <>
        <Helmet>
          <title>Prshete.com | Our Work </title>
          <meta name="description" content="Freelancer Python Backend Developer" />
          <meta name="keywords" content="Freelancer Python Backend Developer, Python Remote Backend Developer" />
          <meta name="author" content="Purushottam Shete" />
        </Helmet>
      
        <Container maxWidth="lg" sx={{mt:10}}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
            
                <Typography color="text.primary">Our Work</Typography>
            </Breadcrumbs>
        </Container>
        <Box sx={{ bgcolor: 'white', minHeight: '500', alignItems: 'center', justifyContent: 'center', display: 'flex', mt: 5, mb: 10 }}>
                <Container maxWidth="lg">
                    <Stack sx={{ alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                        <Typography
                            variant="h2"
                            sx={{
                                fontFamily: 'Poppins,sans-serif',
                                fontWeight: {
                                    lg: 700,
                                    xs: 700
                                },
                                fontSize: {
                                    lg: 50,
                                    xs: 30
                                },
                                color: 'inherit',
                                textDecoration: 'none',
                                textShadow: 2,
                                mb: 2
                            }}
                        >
                          {ourWorksData.title}
                        </Typography>
                        <Typography
                            variant="p"
                            sx={{
                                fontFamily: 'Montserrat,sans-serif',
                                fontWeight: {
                                    lg: 300,
                                    xs: 300
                                },
                                fontSize: {
                                    lg: 20,
                                    xs: 15
                                },
                                color: 'inherit',
                                textDecoration: 'none',
                                textAlign: 'center',
                                px: {
                                    lg: 33,
                                    xs: 6,
                                },
                                mb: 2,
                            }}
                        >
                             {ourWorksData.description}
                        </Typography>
                    </Stack>

                    <Grid container direction="row" spacing={2}>
                        {sliceData.map(([key, caseStudy]) => (
                            <Grid item xs={12} sm={12} md={4} key={caseStudy.id}>
                                <OurWork caseStudy={caseStudy} />
                            </Grid>
                        ))}
                    </Grid>
                    <Stack sx={{ alignItems: 'center', justifyContent: 'center', mt: 5 }} spacing={2}>
                        <Pagination
                            count={Math.ceil(totalOurWorks / ourWorksPerPage)}
                            variant="outlined"
                            page={currentPage}
                            onChange={handleChange}
                        />
                    </Stack>
                </Container>
            </Box>
            <BlueContainer/>

    </>
    );
}

export default OurWorks;