import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Stack,
  List,
  ListItem,
  IconButton,
  Button,
} from "@mui/material";
import { Helmet } from "react-helmet";
import data from "../../constants/data";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useParams } from "react-router-dom";
import ServicesCard from "./ServicesCard";
import OurWorksCarousel from "../ourworks/OurWorksCarousel";
import ResourcesCarousel from "../resources/ResourcesCarousel";

const ServicePage = () => {
  const { id } = useParams();

  const ourworkData = data.ourwork;
  const resourcesData = data.resources;
  const subServicesData = data.services.sub;
  const service = Object.values(subServicesData).find(
    (item) => item.url === id
  );

  const [hoveredService, setHoveredService] = useState(
    service.sub[Object.keys(service.sub)[0]]
  );

  const handleHover = (service) => {
    setHoveredService(service);
  };

  return (
    <>
      <Helmet>
        <title>Prshete.com | Services </title>
        <meta
          name="description"
          content="Freelancer Python Backend Developer"
        />
        <meta
          name="keywords"
          content="Freelancer Python Backend Developer, Python Remote Backend Developer"
        />
        <meta name="author" content="Purushottam Shete" />
      </Helmet>

      <Box
        sx={{
          marginRight: { xs: 1, sm: 7 },
          marginLeft: { xs: 1, sm: 7 },
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: { xs: "none", sm: "block" },
            background: `url(${service.banner}) lightgray 50% / cover no-repeat`,
            width: "100%",
            height: { xs: 280, sm: 637 },
            justifyContent: "flex-start",
            textAlign: "left",
            paddingLeft: 3,
            paddingTop: { xs: 2, sm: 5 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              position: "absolute",
              top: "30%", 
              left: 3, 
              fontFamily: "Poppins, Helvetica",
              fontWeight: "medium",
              fontSize: { xs: 40, sm: 65 }, 
              color: "black",
              zIndex: 1, 
            }}
          >
            {service.title}
          </Typography>

          <Typography
            variant="h2"
            sx={{
              position: "absolute",
              top: "45%",
              left: 3,
              fontFamily: "Poppins, Helvetica",
              fontWeight: "light",
              fontSize: { xs: 20, sm: 40 },
              color: "black",
              zIndex: 1,
            }}
          >
            {service.text}
          </Typography>
        </Box>

        <Box sx={{ width: "100%", mt:8 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: 40,
                  color: "#595757",
                  lineHeight: "50px",
                  fontStyle: "normal",
                }}
              >
                {service.description1}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: 16,
                  color: "#000",
                  lineHeight: "30px",
                  fontStyle: "normal",
                }}
              >
                {service.description2}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Stack sx={{ alignItems: "center", mx: 4, mt: 6 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: { xs: 700, sm: 600 },
              fontSize: { xs: 26, sm: 50 },
              color: "#000",
              textAlign: "center",
              mb: 1,
              lineHeight: "normal",
              fontStyle: "normal",
            }}
          >
            {service.title}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: "18px", sm: "26px" },
              fontWeight: 400,
              color: "#363636",
              textAlign: "center",
              lineHeight: "normal",
              fontStyle: "normal",
            }}
          >
            {service.oneline}
          </Typography>
        </Stack>
        <Box
          sx={{
            backgroundColor: "#F9F9F9",
            borderRadius: "80px",
            marginBottom: 8
          }}
        >
          <Grid container spacing={4} padding={3} marginTop={5}>
            <Grid item xs={12} sm={6}>
              <Stack>
                <List>
                  {Object.entries(service.sub).map(([key, service]) => (
                    <ListItem
                      key={service.id}
                      sx={{
                        cursor: "pointer",
                        padding: 3,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "100px",
                        "&:hover": {
                          bgcolor: "#E0F5FF",
                          color: "#525252",
                          borderRadius: "1100px",
                          height: "100px",
                          padding: 3,
                          "& .arrow-icon": {
                            backgroundColor: "#0BA0E0",
                            color: "white",
                          },
                        },
                      }}
                      onMouseEnter={() => handleHover(service)}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={service.icon}
                          alt={service.title}
                          style={{
                            width: 50,
                            marginRight: 10,
                          }}
                        />
                        <Typography
                          sx={{
                            color: "#525252",
                            ml: 2,
                            fontWeight: 500,
                            fontSize: "20px",
                            lineHeight: "31px",
                            fontStyle: "normal",
                            fontFamily: "Poppins",
                          }}
                        >
                          {service.title}
                        </Typography>
                      </Box>
                      <IconButton
                        className="arrow-icon"
                        sx={{
                          color: "inherit",
                          backgroundColor: "white",
                          borderRadius: "50%",
                          padding: "8px",
                          transition:
                            "background-color 0.3s ease, color 0.3s ease",
                        }}
                      >
                        <ArrowForwardIosIcon fontSize="small" />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              {hoveredService ? (
                <Box sx={{ marginTop: 6 }}>
                  <Typography
                    sx={{
                      mb: 2,
                      flexGrow: 1,
                      fontSize: "26px",
                      fontWeight: 600,
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                    }}
                  >
                    {hoveredService.title}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: 300,
                          fontFamily: "Poppins",
                          fontStyle: "noraml",
                          mb: 2,
                          lineHeight: "noraml",
                        }}
                      >
                        {hoveredService.description}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <img
                        src={hoveredService.image}
                        alt={hoveredService.title}
                        style={{
                          width: "100%",
                          maxWidth: "400px",
                          height: "auto",
                          borderRadius: "8px",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ textAlign: "flex-start" }}>
                    <Button
                      href={`/services/${hoveredService.url}`}
                      variant="text"
                      sx={{ textTransform: "none" }}
                    >
                      Read More <ArrowForwardIosIcon fontSize="inherit" />
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  Hover over a service to view details
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <OurWorksCarousel data={ourworkData} />
      <ResourcesCarousel data={resourcesData} />
    </>
  );
};

export default ServicePage;
