import {
  ourWorkIcon,
  ourWork1,
  ourWork2,
  ourWork3,
  RoboticesService,
  digitalPlatformService,
  digitalProductService,
  aimal,
  pythonForRoboticsImage,
  reactNtiveVsFlutter,
  cppWithRos,
  // cppWithRosBlog,
  // pythonForRobotics,
  // pythonVsNodejsVsRust,
  // reactNativeVsFlutter,
  robotics_icon,
  digital_product_icon,
  digital_platform_icon,
  data_ai_ml_icon,
  manufacturing_icon,
  logistics_icon,
  healthcare_icon,
  defense_icon,
  rosDevIcon,
  embeddedDevIcon,
  IoMTDevIocn,
  systemSimulestionIcon,
  roboticsBannerImage,
  digitalProductBanner,
  digitalPlatformBanner,
  dataAIMLBanner,
  UIUXIcon,
  webAppIcon,
  nativeMobileAppIcon,
  crossPlatformIcon,
  legacyAppIcon,
  applicationSupportIcon,
  enterpriseIntegrationIcon,
  devopsIcon,
  siteReliabilityIcon,
  testAutomationIconIcon,
  CloudInfrastructureIcon,
  dataEngineeringIcon,
  dataVisualisationIcon,
  AIMLIcon,
  dataScience,
  caseStudy,
  blogBanner,
  homeInspection,
  eCommerce,
  healthCare,
  Logistics,
  healthNutrenist,
  testAutomation,
  webInterface,
  whyPrototyping,
  aimlWithPython
} from "./images";

const data = {
  home: {
    id: 1,
    url: "",
    title: "",
    keyword: "",
    description: "",
    image: "",
    meta: "",
    component: "Home",
    body: "",
    active: true,
    sub: {},
  },
  services: {
    id: 2,
    url: "services",
    title: "Our Services",
    keyword: "",
    description:
      "Professional software services delivering tailored solutions to meet your business needs.",
    image: "",
    meta: "",
    component: "Services",
    body: "",
    active: true,
    sub: {
      'Robotics Services': {
        id: 1,
        url: "robotics",
        icon: robotics_icon,
        banner: roboticsBannerImage,
        title: "Robotics Services",
        keyword: "",
        oneline: "Enhance user experiences and operational efficiency with our tailored Robotics Software Services for intelligent, autonomous solutions.",
        description1: "We specialize in providing cutting-edge robotics development services to help businesses leverage the power of automation and robotics technology.",
        description2: "With our team of skilled engineers and robotics experts, we offer comprehensive solutions tailored to meet the unique needs of our clients across various industries. Whether you are looking to automate manufacturing processes, improve logistics operations, or explore new frontiers in robotics technology, PRSTech is your trusted partner for all your robotics development needs.",
        description:
          "Our Robotics Software Services focus on creating intelligent, reliable software solutions for service robots that enhance user experiences and optimize operational efficiency. We specialize in developing control systems, navigation algorithms, and AI-driven features. Whether it’s for logistics, healthcare, or defence, we tailor our solutions to meet the unique demands of service robots, ensuring they are intuitive, autonomous, and adaptable to dynamic environments. With a commitment to innovation and seamless integration, we help transform service robots into smart, interactive solutions that deliver value and improve customer satisfaction. ",
        image: RoboticesService,
        meta: "",
        component: "RoboticServices",
        body: "",
        active: true,
        sub: {
          "ROS Development": {
            id: 1,
            url: "",
            icon: rosDevIcon,
            title: "ROS Development",
            keyword: "",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            image: RoboticesService,
            meta: "",
            component: "RosDevelopment",
            body: "",
            active: true,
            sub: {},
          },
          "Simulation with Gazebo": {
            id: 2,
            url: "",
            icon: systemSimulestionIcon,
            title: "Simulation with Gazebo",
            keyword: "",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            image: RoboticesService,
            meta: "",
            component: "RoboticServices",
            body: "",
            active: true,
            sub: {},
          },
          "IoT & IoMT Development": {
            id: 3,
            url: "",
            icon: IoMTDevIocn,
            title: "IoT & IoMT Development",
            keyword: "",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            image: RoboticesService,
            meta: "",
            component: "RoboticServices",
            body: "",
            active: true,
            sub: {},
          },
          "Embedded Development": {
            id: 4,
            url: "",
            icon: embeddedDevIcon,
            title: "Embedded Development",
            keyword: "",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            image: RoboticesService,
            meta: "",
            component: "RoboticServices",
            body: "",
            active: true,
            sub: {},
          },
        },
      },
      "Digital Product Services": {
        id: 2,
        icon: digital_product_icon,
        url: "digital-product-engineering",
        title: "Digital Product Services",
        keyword: "",
        banner: digitalProductBanner,
        oneline: "Transform your ideas into impactful digital solutions with our end-to-end Digital Product Development Services.",
        description1: "We specialise in providing professional web development services tailored to meet the unique needs of our clients.",
        description2: "With a client-centric approach, we create custom websites using the latest technologies and frameworks, ensuring they align perfectly with your business goals. From responsive design and e-commerce development to content management systems and web applications, our experienced team delivers high-quality websites that leave a lasting impression. We also offer ongoing maintenance and support, prioritising timely delivery and excellent customer service.",
        description:
          "Our Digital Product Development Services empower businesses to transform ideas into impactful digital solutions. We provide comprehensive support throughout the development journey, including strategy, UX/UI design, engineering, and deployment, ensuring that every product is optimized for performance, scalability, and user experience. Leveraging the latest in digital technologies, cloud platforms, and agile methodologies, we bring products to market quickly and efficiently. Whether you're launching a new digital product or enhancing an existing one, our team is dedicated to delivering innovative, user-centered solutions that drive business growth and customer satisfaction. ",
        image: digitalProductService,
        meta: "",
        component: "DigitalProductServices",
        body: "",
        active: true,
        sub: {
          "UI/UX Design & Research": {
            id: 1,
            url: "",
            icon: UIUXIcon,
            title: "UI/UX Design & Research",
            keyword: "",
            description: "Lorem ipsum dolor sit amet consectetur. At lorem lectus imperdiet sed mattis. Hendrerit sit sagittis sed arcu faucibus libero non elementum. Turpis eu phasellus enim risus. Dui nulla aenean et pellentesque eu elementum at bibendum. Aliquam est at amet turpis placerat semper pellentesque. Et ullamcorper enim et mattis blandit lacus fusce. ",
            image: digitalProductService,
            meta: "",
            component: "DigitalProductEngineering",
            body: "",
            active: true,
            sub: {},
          },
          "Web App Design, Development, and Optimisation": {
            id: 2,
            url: "",
            icon: webAppIcon,
            title: "Web App Design, Development, and Optimisation",
            keyword: "",
            description: "Lorem ipsum dolor sit amet consectetur. At lorem lectus imperdiet sed mattis. Hendrerit sit sagittis sed arcu faucibus libero non elementum. Turpis eu phasellus enim risus. Dui nulla aenean et pellentesque eu elementum at bibendum. Aliquam est at amet turpis placerat semper pellentesque. Et ullamcorper enim et mattis blandit lacus fusce. ",
            image: digitalProductService,
            meta: "",
            component: "DigitalProductEngineering",
            body: "",
            active: true,
            sub: {},
          },
          "Native Mobile App Development": {
            id: 3,
            url: "",
            icon: nativeMobileAppIcon,
            title: "Native Mobile App Development",
            keyword: "",
            description: "Lorem ipsum dolor sit amet consectetur. At lorem lectus imperdiet sed mattis. Hendrerit sit sagittis sed arcu faucibus libero non elementum. Turpis eu phasellus enim risus. Dui nulla aenean et pellentesque eu elementum at bibendum. Aliquam est at amet turpis placerat semper pellentesque. Et ullamcorper enim et mattis blandit lacus fusce. ",
            image: digitalProductService,
            meta: "",
            component: "DigitalProductEngineering",
            body: "",
            active: true,
            sub: {},
          },
          "Cross-Platform Mobile App Development": {
            id: 4,
            url: "",
            icon: crossPlatformIcon,
            title: "Cross-Platform Mobile App Development",
            keyword: "",
            description: "Lorem ipsum dolor sit amet consectetur. At lorem lectus imperdiet sed mattis. Hendrerit sit sagittis sed arcu faucibus libero non elementum. Turpis eu phasellus enim risus. Dui nulla aenean et pellentesque eu elementum at bibendum. Aliquam est at amet turpis placerat semper pellentesque. Et ullamcorper enim et mattis blandit lacus fusce. ",
            image: digitalProductService,
            meta: "",
            component: "DigitalProductEngineering",
            body: "",
            active: true,
            sub: {},
          },
          "Legacy App Migration": {
            id: 5,
            url: "",
            icon: legacyAppIcon,
            title: "Legacy App Migration",
            keyword: "",
            description:"Lorem ipsum dolor sit amet consectetur. At lorem lectus imperdiet sed mattis. Hendrerit sit sagittis sed arcu faucibus libero non elementum. Turpis eu phasellus enim risus. Dui nulla aenean et pellentesque eu elementum at bibendum. Aliquam est at amet turpis placerat semper pellentesque. Et ullamcorper enim et mattis blandit lacus fusce. ",
            image: digitalProductService,
            meta: "",
            component: "DigitalProductEngineering",
            body: "",
            active: true,
            sub: {},
          },
          "Application Support": {
            id: 6,
            url: "",
            icon: applicationSupportIcon,
            title: "Application Support",
            keyword: "",
            description: "Lorem ipsum dolor sit amet consectetur. At lorem lectus imperdiet sed mattis. Hendrerit sit sagittis sed arcu faucibus libero non elementum. Turpis eu phasellus enim risus. Dui nulla aenean et pellentesque eu elementum at bibendum. Aliquam est at amet turpis placerat semper pellentesque. Et ullamcorper enim et mattis blandit lacus fusce. ",
            image: digitalProductService,
            meta: "",
            component: "DigitalProductEngineering",
            body: "",
            active: true,
            sub: {},
          },
          "Enterprise Integration": {
            id: 7,
            url: "",
            icon: enterpriseIntegrationIcon,
            title: "Enterprise Integration",
            keyword: "",
            description: "Lorem ipsum dolor sit amet consectetur. At lorem lectus imperdiet sed mattis. Hendrerit sit sagittis sed arcu faucibus libero non elementum. Turpis eu phasellus enim risus. Dui nulla aenean et pellentesque eu elementum at bibendum. Aliquam est at amet turpis placerat semper pellentesque. Et ullamcorper enim et mattis blandit lacus fusce. ",
            image: digitalProductService,
            meta: "",
            component: "DigitalProductEngineering",
            body: "",
            active: true,
            sub: {},
          },
        },
      },
      "Digital Platform Services": {
        id: 3,
        icon: digital_platform_icon,
        url: "digital-Platform-engineering",
        title: "Digital Platform Services",
        keyword: "",
        banner: digitalPlatformBanner,
        oneline: "Build scalable, resilient platforms with our expert Digital Platform Engineering Services for seamless digital transformation.",
        description1: "We specialise in providing professional web development services tailored to meet the unique needs of our clients.",
        description2: "With a client-centric approach, we create custom websites using the latest technologies and frameworks, ensuring they align perfectly with your business goals. From responsive design and e-commerce development to content management systems and web applications, our experienced team delivers high-quality websites that leave a lasting impression. We also offer ongoing maintenance and support, prioritising timely delivery and excellent customer service.",
        description:
          "Our Digital Platform Engineering Services are designed to build robust, scalable platforms that drive digital transformation and enhance user engagement. We specialize in creating flexible, cloud-based architectures that enable seamless integration, high availability, and easy scaling. From designing foundational infrastructure to implementing advanced microservices, data management, and security features, our team ensures your platform is optimized for both performance and resilience. With a focus on agility and innovation, we deliver tailored engineering solutions that empower your business to adapt quickly, support complex ecosystems, and deliver exceptional digital experiences.",
        image: digitalPlatformService,
        meta: "",
        component: "DigitalPlatformServices",
        body: "",
        active: true,
        sub: {
          DevOps: {
            id: 1,
            url: "",
            icon: devopsIcon,
            title: "DevOps",
            keyword: "",
            description: "",
            image: digitalPlatformService,
            meta: "",
            component: "DevOps",
            body: "",
            active: true,
            sub: {},
          },
          "Site Reliability Engineering (SRE)": {
            id: 2,
            url: "",
            icon: siteReliabilityIcon,
            title: "Site Reliability Engineering (SRE)",
            keyword: "",
            description: "",
            image: digitalPlatformService,
            meta: "",
            component: "DigitalProductEngineering",
            body: "",
            active: true,
            sub: {},
          },
          "Test Automation": {
            id: 3,
            url: "",
            icon: testAutomationIconIcon,
            title: "Test Automation",
            keyword: "",
            description: "",
            image: digitalPlatformService,
            meta: "",
            component: "DigitalProductEngineering",
            body: "",
            active: true,
            sub: {},
          },
          "Cloud Infrastructure Services": {
            id: 3,
            url: "",
            icon: CloudInfrastructureIcon,
            title: "Cloud Infrastructure Services",
            keyword: "",
            description: "",
            image: digitalPlatformService,
            meta: "",
            component: "DigitalProductEngineering",
            body: "",
            active: true,
            sub: {},
          },
        },
      },
      "Data, Al, ML": {
        id: 4,
        icon: data_ai_ml_icon,
        banner: dataAIMLBanner,
        url: "data-analytics-and-ai",
        title: "Data, Al, ML",
        keyword: "",
        oneline: "Unlock the power of your data with our comprehensive Data, AI, and ML Services for smarter insights and automation.",
        description1: "We specialise in providing professional web development services tailored to meet the unique needs of our clients.",
        description2: "With a client-centric approach, we create custom websites using the latest technologies and frameworks, ensuring they align perfectly with your business goals. From responsive design and e-commerce development to content management systems and web applications, our experienced team delivers high-quality websites that leave a lasting impression. We also offer ongoing maintenance and support, prioritising timely delivery and excellent customer service.",
        description:
          "Our Data, AI, and ML Services empower businesses to harness the full potential of their data, driving smarter decisions and deeper insights. We provide end-to-end solutions, from data strategy and engineering to advanced analytics, machine learning model development, and AI-driven automation. Leveraging cutting-edge algorithms and cloud-based tools, we design tailored models that enhance predictive accuracy, automate complex processes, and uncover valuable patterns in your data. Whether you're looking to optimize operations, personalize customer experiences, or innovate with AI, our team delivers powerful, scalable solutions that transform data into a strategic asset.",
        image: aimal,
        meta: "",
        component: "DataAnalyticsAIServices",
        body: "",
        active: true,
        sub: {
          "Data Engineering": {
            id: 1,
            url: "",
            icon: dataEngineeringIcon,
            title: "Data Engineering",
            keyword: "",
            description: "",
            image:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVuNVRNX6PQ88lBmcXWI6qCbjkManmdud3mLAXZJs-dW0dQVShCWIfYZiZ9RpZn0hodOI&usqp=CAU",
            meta: "",
            component: "DigitalProductEngineering",
            body: "",
            active: true,
            sub: {},
          },
          "Data Visualisation": {
            id: 2,
            url: "",
            icon: dataVisualisationIcon,
            title: "Data Visualisation",
            keyword: "",
            description: "",
            image:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVuNVRNX6PQ88lBmcXWI6qCbjkManmdud3mLAXZJs-dW0dQVShCWIfYZiZ9RpZn0hodOI&usqp=CAU",
            meta: "",
            component: "DigitalProductEngineering",
            body: "",
            active: true,
            sub: {},
          },
          "Artificial Intelligence and Machine Learning (AI/ML)": {
            id: 3,
            url: "",
            icon: AIMLIcon,
            title: "Artificial Intelligence and Machine Learning (AI/ML)",
            keyword: "",
            description: "",
            image:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVuNVRNX6PQ88lBmcXWI6qCbjkManmdud3mLAXZJs-dW0dQVShCWIfYZiZ9RpZn0hodOI&usqp=CAU",
            meta: "",
            component: "DigitalProductEngineering",
            body: "",
            active: true,
            sub: {},
          },
          "Data Science Consultancy": {
            id: 4,
            url: "",
            icon: dataScience,
            title: "Data Science Consultancy",
            keyword: "",
            description: "",
            image:
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVuNVRNX6PQ88lBmcXWI6qCbjkManmdud3mLAXZJs-dW0dQVShCWIfYZiZ9RpZn0hodOI&usqp=CAU",
            meta: "",
            component: "DigitalProductEngineering",
            body: "",
            active: true,
            sub: {},
          },
        },
      },
    },
  },
  industries: {
    id: 3,
    url: "industries",
    title: "Our Industry Expertise ",
    keyword: "",
    description: "one liner for section",
    image: "",
    meta: "",
    component: "Industries",
    body: "",
    active: true,
    sub: {
      Manufacturing: {
        id: 1,
        icon: manufacturing_icon,
        url: "manufacturing",
        title: "Manufacturing",
        keyword: "",
        description: "",
        image:
          "https://okcredit-blog-images-prod.storage.googleapis.com/2020/09/auto-manufacturing.jpg",
        meta: "",
        component: "Manufacturing",
        body: "",
        active: true,
        sub: {},
      },
      Logistic: {
        id: 2,
        icon: logistics_icon,
        url: "logistic",
        title: "Logistic",
        keyword: "",
        description: "",
        image:
          "https://media.licdn.com/dms/image/D4D12AQEWuEdRZjFmrw/article-cover_image-shrink_600_2000/0/1717415880617?e=2147483647&v=beta&t=A42pxLswuhlNkttrZ7c5_1gqPO2vkIOkqArnm7QgQWg",
        meta: "",
        component: "Logistic",
        body: "",
        active: true,
        sub: {},
      },
      Defense: {
        id: 3,
        icon: defense_icon,
        url: "defense",
        title: "Defense",
        keyword: "",
        description: "",
        image:
          "https://okcredit-blog-images-prod.storage.googleapis.com/2020/09/auto-manufacturing.jpg",
        meta: "",
        component: "Defense",
        body: "",
        active: true,
        sub: {},
      },
      Healthcare: {
        id: 4,
        icon: healthcare_icon,
        url: "healthcare",
        title: "Healthcare",
        keyword: "",
        description: "",
        image:
          "https://okcredit-blog-images-prod.storage.googleapis.com/2020/09/auto-manufacturing.jpg",
        meta: "",
        component: "Healthcare",
        body: "",
        active: true,
        sub: {},
      },
    },
  },
  ourwork: {
    id: 4,
    url: "ourwork",
    title: "Our Work",
    keyword: "",
    description:
      "Some of our Success Stories",
    image: "",
    meta: "",
    component: "OurWork",
    body: "",
    active: true,
    sub: {
      "Transforming Manual Processes Through Digital Innovation": {
        id: 3,
        url: "transforming-manual-processes-through-digital-innovation",
        title: "Transforming Manual Processes Through Digital Innovation",
        keyword: "",
        banner: homeInspection,
        text: 'Case Study',
        description: "Developing a mobile app for home inspections was initiated by a real estate company to digitize and streamline the home inspection process for homebuyers, sellers, realtors, and inspectors. The goal was to create an intuitive, reliable platform to manage inspection reports, track defects, and offer recommendations while improving communication between stakeholders.",
        icon: ourWorkIcon,
        image: homeInspection,
        meta: "",
        component: "HomeInspection",
        body: "",
        active: true,
        challengaes: {
          images: ["", ""],
          body: "Home inspection is a critical step in real estate transactions. However, the traditional method—relying on paper reports or fragmented digital tools—led to inefficiencies, lost data, and a lack of transparency for buyers and sellers. Realtors found it hard to keep track of inspections, and home inspectors spent considerable time compiling and delivering reports. The goal was to develop a unified mobile app that would",
          bullets: [
            "Eliminate paper-based processes",
            "Provide real-time access to inspection reports",
            "Enable better tracking of property defects",
            "Improve communication between all parties involved",
          ],
        },
        solution: {
          images: ["", ""],
          body: "The proposed solution was a user-friendly mobile app available on both iOS and Android platforms, allowing home inspectors to conduct, record, and submit comprehensive property inspections with the use of smartphones or tablets. The app would also enable buyers and sellers to view and comment on inspections in real-time, streamlining the entire process. Some of the Key features.",
          bullets: [
            "Inspection Report Generation",
            "Defect Tracking & Categorization",
            "Scheduling & Reminders",
            "Collaboration",
           " Offline Capability",
            "Cloud Storage Integration",
            "Push Notifications",
            "Analytics and Insights",
          ],
        },
        benifits: {
          icon:healthcare_icon,
          industry: "Real Estate",
          revenue: "",
          images: ["", ""],
          body: "",
          bullets: [
            "Increased Efficiency",
           "Higher Transparency",
            "Reduced Paperwork",
            "Positive User Feedback",
            "Scalability",
          ],
        },
        sub: {},
      },
      "IoT-Driven Digital Platform for Next-Gen Innovation": {
        id: 1,
        url: "iot-driven-digital-platform-for-next-gen-innovation",
        title: "IoT-Driven Digital Platform for Next-Gen Innovation",
        banner: healthNutrenist,
        text: 'Case Study',
        keyword: "",
        description: "Health Nutrenist is a digital platform designed to offer personalized fitness and nutrition programs to users with varying health goals. It integrates fitness coaching, nutritional planning, and wellness tracking into a unified experience. ",
        icon: ourWorkIcon,
        image: healthNutrenist,
        meta: "",
        component: "RovDashboard",
        body: "",
        active: true,
        challengaes: {
          images: ["", ""],
          body: "In today's fast-paced world, individuals struggle to maintain a healthy lifestyle due to limited access to personalized fitness and nutrition guidance. users find it challenging to track their progress in a meaningful way and often miss out on the benefits of real-time feedback and community support. While wearable fitness devices are becoming increasingly popular, there is a lack of seamless integration between these devices and comprehensive health platforms that offer both fitness and nutrition management. Key challenges.",
          bullets: [
            "Lack of Personalization",
            "Inconsistent Progress Tracking",
            "Lack of Wearable Integration",
            "Limited Engagement and Support",
          ],
        },
        solution: {
          images: ["", ""],
          body: "To create a comprehensive platform that offers personalized fitness plans and nutrition advice for users at different fitness levels, addressing their unique health goals (e.g., weight loss, muscle gain, maintaining a balanced diet). With key Features.",
          bullets: [
            "Multiple Fitness Programs",
            "Nutrition Plans",
            "Progress Tracking",
            "Integration with Wearables",
            "Community & Support",
          ],
        },
        benifits: {
          industry: "Health Fitness",
          revenue: "",
          images: ["", ""],
          body: "",
          bullets: [
           "Personalized Fitness Programs",
          "Tailored Nutrition Plans",
          "Seamless Wearable Integration",
          "Holistic Health Tracking",
          "Community Engagement",
          "Expert Consultations"
          ],
        },

        sub: {},
      },
      "Modernization of PHP-Based Legacy Application": {
        id: 2,
        url: "modernization-of-php-based-legacy-application",
        title: "Modernization of PHP-Based Legacy Application",
        keyword: "",
        banner: eCommerce,
        text: 'Case Study',
        description: "Modernization of a decade-old PHP-based monolithic backend system used by a large e-commerce platform. The backend was responsible for managing customer data, orders, inventory, and payments. However, it was becoming a bottleneck for scaling, security, and integration with newer systems. The goal was to modernize the backend by transitioning from a monolithic PHP application to a cloud-native microservices architecture with a modern technology stack.",
        icon: ourWorkIcon,
        image: eCommerce,
        meta: "",
        component: "PredictiveMaintenance",
        body: "",
        active: true,
        challengaes: {
          images: ["", ""],
          body: "A large e-commerce platform relies on a decade-old PHP-based monolithic backend system to handle critical business operations such as customer management, order processing, inventory tracking, and payment processing. The platform has experienced significant growth, which has exposed several limitations in the existing system, leading to frequent issues related to performance, scalability, and security. The company faces increasing competition and needs to innovate quickly to meet customer demands and enhance operational efficiency. Key challenges.",
          bullets: [
           "Monolithic Architecture",
            "Performance Bottlenecks",
            "Scalability Issues",
            "Security Concerns",
            "Maintenance and Technical Debt",
            "Lack of Cloud Support",
          ],
        },
        solution: {
          images: ["", ""],
          body: "To address the challenges outlined, we re-architected the monolithic PHP-based backend into a cloud-native, microservices-based architecture. This modernization effort introduced a set of new technologies and practices that enhanced the platform’s performance, scalability, security, and maintainability. With key features.",
          bullets: [
            "Microservices Architecture",
            "Cloud-Native",
            "Asynchronous Processing",
            "Enhanced Security",
            "Polyglot Database Support",
            "Automated CI/CD Pipelines",
            "API Gateway",
            "Monitoring and Logging"
          ],
        },
        benifits: {
          industry: "E-Commerce",
          revenue: "",
          images: ["", ""],
          body: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
          bullets: [
            "Performance",
            "Scalability",
            "Security",
            "Developer Efficiency",
            "Tech Stack Used",
            "Legacy",
            "Modernized"
          ],
        },
        sub: {},
      },
      "Faster Time to Market with DevOps Adoption": {
        id: 4,
        url: "faster-time-to-market-with-devops-adoption",
        title: "Faster Time to Market with DevOps Adoption",
        keyword: "",
        banner: healthCare,
        text: 'Case Study',
        description: "The implementation of a DevOps culture and CI/CD pipeline revolutionized the healthcare provider's software delivery process. By automating testing, deployment, and compliance, the company not only improved the speed and quality of releases but also maintained strict adherence to healthcare regulations. The project served as a model for integrating security, compliance, and automation in healthcare software development, offering a scalable solution for future growth.",
        icon: ourWorkIcon,
        image:healthCare,
        meta: "",
        component: "Slamalgorithm",
        body: "",
        active: true,
        challengaes: {
          images: ["", ""],
          body: "The healthcare provider was experiencing significant challenges with the delivery of its core application. These challenges were impacting both the performance of the application and the organization's ability to maintain compliance with healthcare regulations.",
          bullets: [
            "Slow release cycles",
            "Inconsistent deployment processes",
            "Lack of automation",
            "Regulatory compliance",
          ],
        },
        solution: {
          images: ["", ""],
          body: "The proposed solution was to implement a DevOps culture and an automated CI/CD pipeline, incorporating best practices for security, compliance, and continuous delivery.",
          bullets: [
            "Cloud Migration & Containerization",
            "CI/CD Pipeline Setup",
           " Security & Compliance Automation",
            "Monitoring & Feedback",
            "Culture Change: DevOps Adoption",
          ],
        },
        benifits: {
          industry: "Healthcare",
          revenue: "",
          images: ["", ""],
          body: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
          bullets: [
            "Faster Release Cycles",
            "Improved Software Quality",
            "Enhanced Security & Compliance",
            "Higher Customer Satisfaction",
            "Cost Reduction",
          ],
        },
        sub: {},
      },
      "Real-Time Data Platform for End-to-End Visibility and Growth": {
        id: 3,
        url: "real-time-data-platform-for-end-to-end-visibility-and-growth",
        title: "Real-Time Data Platform for End-to-End Visibility and Growth",
        keyword: "",
        banner: Logistics,
        text: 'Case Study',
        description: "The implementation of a real-time data platform transformed the logistics company’s operations by providing end-to-end visibility into their supply chain, improving route planning, and enabling faster, data-driven decision-making. The platform not only reduced operational costs and increased customer satisfaction but also positioned the company to scale efficiently as it grew.",
        icon: ourWorkIcon,
        image: Logistics,
        meta: "",
        component: "HomeInspection",
        body: "",
        active: true,
        challengaes: {
          images: ["", ""],
          body: "The logistics company faced several operational inefficiencies due to its outdated data infrastructure, which limited its ability to make real-time decisions on shipments, route planning, and inventory management. The key challenges.",
          bullets: [
            "Lack of real-time visibility",
            "Inefficient route planning",
            "Siloed data sources",
           " Inability to scale",
           " Customer dissatisfaction",
          ],
        },
        solution: {
          images: ["", ""],
          body: "The logistics company implemented a real-time data platform designed to ingest, process, and analyze massive amounts of data from a variety of sources. The platform was built using modern data processing technologies and designed to scale with growing demand. Key Features.",
          bullets: [
           " Real-time Data Ingestion and Processing",
            "Data Storage and Scalability",
            "Data Integration and Unification",
            "Real-time Analytics and Reporting",
            "Route Optimization and Cost Reduction",
            "Monitoring, Alerts, and Incident Management",
           " Scalability and Resilience",
          ],
        },
        benifits: {
          icon:healthcare_icon,
          industry: "Logistics & Supply Chain",
          revenue: "",
          images: ["", ""],
          body: "",
          bullets: [
            "Improved Shipment Tracking Accuracy",
            "Reduced Fuel Costs and Optimized Routes",
            "Faster Decision-Making",
            "Scalability and Flexibility",
            "Increased Customer Satisfaction",
            "Proactive Incident Management",
           " Optimized Warehouse Operations"
          ],
        },
        sub: {},
      },
      "Improving Reliability and Scalability for a Growing E-Com.": {
        id: 3,
        url: "improving-reliability-and-scalability-for-a-growing-e-com",
        title: "Improving Reliability and Scalability for a Growing E-Com.",
        keyword: "",
        banner: ourWork2,
        text: 'Case Study',
        description: "The e-commerce platform successfully scaled to meet growing demand by implementing the SRE principles, reduced downtime, and improved overall system performance. This holistic approach allowed the business to thrive during periods of rapid growth without sacrificing reliability.",
        icon: ourWorkIcon,
        image:  ourWork2,
        meta: "",
        component: "HomeInspection",
        body: "",
        active: true,
        challengaes: {
          images: ["", ""],
          body: "A mid-sized e-commerce platform that specializes in selling custom products online. The company has been experiencing rapid growth, especially during holiday seasons, and has faced several outages, particularly during peak traffic periods. Key challenges.",
          bullets: [
            "System Downtime",
            "Slow Response Times",
            "Incident Response Issues"
          ],
        },
        solution: {
          images: ["", ""],
          body: "To address the challenges of system downtime, slow response times, and inefficient incident management, the SRE team implemented several solutions using SRE principles.",
          bullets: [
            "Define Service Level Objectives (SLOs)",
            "Error Budgeting",
            "Automated Monitoring and Incident Response",
            "Progressive Feature Rollouts (Canary Deployment)",
            "Scalability with Autoscaling and Kubernetes",
            "Post-Incident Reviews (PIRs)"
          ],
        },
        benifits: {
          icon:healthcare_icon,
          industry: "E-Commerce",
          revenue: "",
          images: ["", ""],
          body: "",
          bullets: [
            "Increased Uptime",
            "Faster Response Times",
           " Reduced Incident Impact ",
            "More Balanced Innovation",
          ],
        },
        sub: {},
      },
      "Improving Software Quality with Test Automation.": {
        id: 3,
        url: "improving-software-quality-with-test-automation",
        title: "Improving Software Quality with Test Automation",
        keyword: "",
        banner: testAutomation,
        text: 'Case Study',
        description: "A mid size e-commerce organization successfully implemented test automation to improve software quality, reduce manual testing efforts, and increase the speed of releases.",
        icon: ourWorkIcon,
        image: testAutomation,
        meta: "",
        component: "HomeInspection",
        body: "",
        active: true,
        challengaes: {
          images: ["", ""],
          body: "The company faced challenges in ensuring the stability of its platform, especially during periods of rapid feature development. With each release, more defects were being found late in the development cycle or in production, leading to increased customer dissatisfaction. Manual testing efforts were time-consuming and error-prone, causing delays in releasing new features.",
          bullets: [
            "Frequent software releases",
            "Higher of regression defects",
           " Longer Release Cycle"
          ],
        },
        solution: {
          images: ["", ""],
          body: "Test automation helped the company improve software quality, reduce regression defects, and accelerate the release process. By investing in automation, they were able to scale their testing efforts and deliver more stable software to customers. With process",
          bullets: [
            "Test Planning",
           " Test Script Development",
            "Integration into CI Pipeline",
          ],
        },
        benifits: {
          icon:healthcare_icon,
          industry: "E-Commerce",
          revenue: "",
          images: ["", ""],
          body: "",
          bullets: [
            "Reduced Manual Efforts",
            "Faster Time to Market",
            "Improved Product Quality",
            "Enhanced Collaboration"
          ],
        },
        sub: {},
      },
      
    },
  },
  resources: {
    id: 5,
    url: "resources",
    title: "Our Resources",
    keyword: "",
    description:
      "Professional software services delivering tailored solutions to meet your business needs",
    image: "",
    meta: "",
    component: "Resources",
    body: "",
    active: true,
    sub: {
      "Web Interface for ROS-based Robots": {
        id: 4,
        url: "web-interface-for-ros-based-robots",
        title: "Web Interface for ROS-based Robots",
        keyword: "",
        banner: webInterface,
        file: "/md/web-interface-for-ros.md",
        description:
          "Lorem ipsum dolor sit amet consectetur. Nec eget euismod amet molestie tortor ultrices aliquet dapibus sit. ",
        image:webInterface,
        meta: "",
        component: "PythonVsNodejsVsRust",
        body: "",
        active: true,
        sub: {},
      },
      "Why Prototyping for Digital Product Development?": {
        id: 1,
        url: "why prototyping-for-digital-product-development",
        title: "Why Prototyping for Digital Product Development?",
        banner: whyPrototyping,
        keyword: "",
        file: "/md/why-prototyping.md",
        description:
          "Lorem ipsum dolor sit amet consectetur. Nec eget euismod amet molestie tortor ultrices aliquet dapibus sit. ",
        image: whyPrototyping,
        meta: "",
        component: "RoboticsDevelopment",
        body: "",
        active: true,
        sub: {},
      },
      "AI ML With Python": {
        id: 2,
        url: "ai-ml-with-python",
        title: "AI ML With Python",
        keyword: "",
        banner: aimlWithPython,
        file: "/md/ai-ml-with-python.md",
        description:
          "Lorem ipsum dolor sit amet consectetur. Nec eget euismod amet molestie tortor ultrices aliquet dapibus sit. ",
        image: aimlWithPython,
        meta: "",
        component: "CppWithRos",
        body: "",
        active: true,
        sub: {},
      },
      // "React Native vs Flutter": {
      //   id: 3,
      //   url: "react-native-vs-flutter",
      //   title: "React Native vs Flutter",
      //   keyword: "",
      //   banner: blogBanner,
      //   file: "/md/react-native-vs-flutter.md",
      //   description:
      //     "Lorem ipsum dolor sit amet consectetur. Nec eget euismod amet molestie tortor ultrices aliquet dapibus sit. ",
      //   image: reactNtiveVsFlutter,
      //   meta: "",
      //   component: "ReactNativeVsFlutter",
      //   body: "",
      //   active: true,
      //   sub: {},
      // },
      // "Python vs NodeJs vs Rust": {
      //   id: 4,
      //   url: "python-vs-nodejs-vs-rust",
      //   title: "Python vs NodeJs vs Rust",
      //   keyword: "",
      //   banner: blogBanner,
      //   file: "/md/python-vs-nodejs-vs-rust.md",
      //   description:
      //     "Lorem ipsum dolor sit amet consectetur. Nec eget euismod amet molestie tortor ultrices aliquet dapibus sit. ",
      //   image:
      //     "https://okcredit-blog-images-prod.storage.googleapis.com/2020/09/auto-manufacturing.jpg",
      //   meta: "",
      //   component: "PythonVsNodejsVsRust",
      //   body: "",
      //   active: true,
      //   sub: {},
      // },

    },
  },
  aboutus: {
    id: 7,
    url: "about",
    title: "",
    keyword: "",
    description: "",
    image: "",
    meta: "",
    component: "AboutUs",
    body: "",
    active: true,
    sub: {},
  },
  contactus: {
    id: 6,
    url: "contact",
    title: "",
    keyword: "",
    description: "",
    image: "",
    meta: "",
    component: "ContactUs",
    body: "",
    active: true,
    sub: {},
  },
};

export default data;
