import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { Alert } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import emailjs from "emailjs-com";

const EmailContactForm = () => {
    const [alert, setAlert] = useState(null);
    const { register, handleSubmit, reset, formState } = useForm();
    const { errors, isSubmitting } = formState;

    const onSubmit = (formData) => {
        emailjs
            .send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, formData, process.env.REACT_APP_EMAILJS_USER_ID)
            .then(
                (result) => {
                    setAlert({
                        severity: 'success',
                        message: 'The message was sent successfully. Thanks for contacting us!',
                    });
                    reset();
                },
                (error) => {
                    setAlert({
                        severity: 'error',
                        message: 'Error sending message: ' + error.text,
                    });
                }
            );
    };

    return (
        <Box
            component="form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mb: 3 }}
        >
            {alert && (
                <Alert
                    severity={alert.severity}
                    sx={{ mb: 1, mt: 1 }}
                >
                    {alert.message}
                </Alert>
            )}
            <FormControl fullWidth sx={{ mb: 1 }}>
                <TextField
                    id="outlined-name"
                    label="First Name"
                    required
                    {...register("firstName", { required: 'This field is required' })}
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 1 }}>
                <TextField
                    label="Last Name"
                    required
                    {...register("lastName", { required: 'This field is required' })}
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 1 }}>
                <TextField
                    label="Phone Number"
                    required
                    {...register("phone", { 
                        required: 'This field is required', 
                        pattern: {
                            value: /^(\+?\d{1,4}[\s-]?)?(\(?\d{3}\)?[\s-]?)?\d{3}[\s-]?\d{4}$/,
                            message: "Invalid phone number"
                        }
                    })}
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 1 }}>
                <TextField
                    label="Email"
                    required
                    {...register("email", { 
                        required: 'This field is required', 
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address"
                        }
                    })}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 1 }}>
                <TextField
                    label="Company Name"
                    required
                    {...register("company", { required: 'This field is required' })}
                    error={!!errors.company}
                    helperText={errors.company?.message}
                />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 1 }}>
                <TextField
                    label="How can we help ?"
                    required
                    multiline
                    rows={4}
                    {...register("message", { required: 'This field is required' })}
                    error={!!errors.message}
                    helperText={errors.message?.message}
                />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 1 }}>
                <LoadingButton 
                    variant="contained" 
                    type="submit" 
                    loading={isSubmitting}
                >
                    Send
                </LoadingButton>
            </FormControl>
        </Box>
    );
};

export default EmailContactForm;