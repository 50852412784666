import React from "react";
import { Container, Typography, Breadcrumbs, Link, Box, Stack } from "@mui/material";
import { Helmet } from "react-helmet";
import data from "../../constants/data";
import {useParams} from 'react-router-dom';
import OurWorksCarousel from '../ourworks/OurWorksCarousel';
import ResourcesCarousel from '../resources/ResourcesCarousel';


const IndustryPage = () => {
    const { id } = useParams(); 

    const ourworkData = data.ourwork;
    const resourcesData = data.resources
    const industriesData = data.industries.sub;
    const industry =  Object.values(industriesData).find(item => item.url === id);

  return (
    <>
      <Helmet>
        <title>Prshete.com | Industries </title>
        <meta
          name="description"
          content="Freelancer Python Backend Developer"
        />
        <meta
          name="keywords"
          content="Freelancer Python Backend Developer, Python Remote Backend Developer"
        />
        <meta name="author" content="Purushottam Shete" />
      </Helmet>
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          <Link underline="hover" color="inherit" href="/industries">
            Industries
          </Link>

          <Typography color="text.primary">{industry.title}</Typography>
        </Breadcrumbs>
      </Container>
      <Box 
            sx={{ 
                bgcolor: 'white', 
                minHeight: '300px', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                mt: 2, 
                mb: 4 
            }}
        >
            <Container maxWidth="lg">
                <Stack sx={{ alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                    <Typography
                        variant="h2"
                        component="h2"
                        sx={{
                            fontFamily: 'Poppins, sans-serif',
                            fontWeight: 700,
                            fontSize: { lg: 40, xs: 20 },
                            color: 'inherit',
                            textDecoration: 'none',
                            textShadow: 2,
                            mb: 2
                        }}
                    >
                        {industry.title}
                    </Typography>
                    <Typography
                        variant="body1"
                        component="p"
                        sx={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontWeight: 300,
                            fontSize: { lg: 20, xs: 15 },
                            color: 'inherit',
                            textDecoration: 'none',
                            textAlign: 'center',
                            px: { lg: 33, xs: 6 },
                            mb: 2,
                        }}
                    >
                        {industry.description}
                    </Typography>
                </Stack>

                <Box 
                    sx={{ 
                        border: '1px solid #ccc', 
                        borderRadius: '8px', 
                        padding: 2, 
                        bgcolor: '#f9f9f9', 
                        transition: '0.3s',
                        '&:hover': {
                            bgcolor: '#e0e0e0',
                            boxShadow: 3,
                        },
                        textAlign: 'left'
                    }}
                >
                    <img 
                        src={industry.image} 
                        alt={industry.title} 
                        style={{ width: '50%', borderRadius: '8px', marginBottom: '1rem' }} 
                    />
                </Box>
            </Container>
        </Box>

        <OurWorksCarousel data={ourworkData}/>
        <ResourcesCarousel data={resourcesData}/>
    </>
  );
};

export default IndustryPage;