import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const Team = ({ emp }) => {
  return (
    <Card sx={{ marginBottom: 2, boxShadow: 2 }}>
      <CardMedia
        component="img"
        height="150"
        image={emp.image}
        alt={emp.name}
        sx={{ objectFit: "cover" }}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {emp.name}
        </Typography>
        <Button
          href={emp.linkedin}
          sx={{ my: 2, color: "black", display: "block" }}
        >
         <LinkedInIcon/>
         
        </Button>
      </CardContent>
    </Card>
  );
};

export default Team;