import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Grid,
} from "@mui/material";

const CollaborationButton = ({ collaborationData }) => {
  return (
    <>
      <Grid item xs={12} lg={4}>
        <Card
          sx={{
            borderRadius: { xs: 2, sm: "80px 19.085px 19.085px 80px" },
            boxShadow: 3,
          }}
        >
          <CardMedia
            component="img"
            sx={{
              height: "220px",
              objectFit: "cover",
            }}
            image={collaborationData[0].image}
            alt={collaborationData[0].title}
          />
          <Box
            sx={{
              backgroundColor: "#011B6E",
              color: "white",
              padding: 1,
              opacity: "60%",
              textAlign: "center",
              borderTopLeftRadius: 2,
              borderTopRightRadius: 2,
              marginTop: -3,
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              {collaborationData[0].title}
            </Typography>
          </Box>
          <CardContent>
            <Typography variant="body1" sx={{ marginTop: 1 }}>
              {collaborationData[0].text}
            </Typography>
            <List>
              {collaborationData[0].benifits.map((service, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={service} />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Card sx={{ borderRadius: { xs: 2, sm: "19.085px" }, boxShadow: 3 }}>
          <CardMedia
            component="img"
            sx={{
              height: "220px",
              objectFit: "cover",
            }}
            image={collaborationData[1].image}
            alt={collaborationData[1].title}
          />
          <Box
            sx={{
              backgroundColor: "#011B6E",
              color: "white",
              padding: 1,
              opacity: "60%",
              textAlign: "center",
              borderTopLeftRadius: 2,
              borderTopRightRadius: 2,
              marginTop: -3,
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              {collaborationData[1].title}
            </Typography>
          </Box>
          <CardContent>
            <Typography variant="body1" sx={{ marginTop: 1 }}>
              {collaborationData[1].text}
            </Typography>
            <List>
              {collaborationData[1].benifits.map((service, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={service} />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Card
          sx={{
            borderRadius: { xs: 2, sm: "19.085px 80px 80px 19.085px" },
            boxShadow: 3,
          }}
        >
          <CardMedia
            component="img"
            sx={{
              height: "220px",
              objectFit: "cover",
            }}
            image={collaborationData[2].image}
            alt={collaborationData[2].title}
          />
          <Box
            sx={{
              backgroundColor: "#011B6E",
              color: "white",
              padding: 1,
              opacity: "60%",
              textAlign: "center",
              borderTopLeftRadius: 2,
              borderTopRightRadius: 2,
              marginTop: -3,
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              {collaborationData[2].title}
            </Typography>
          </Box>
          <CardContent>
            <Typography variant="body1" sx={{ marginTop: 1 }}>
              {collaborationData[2].text}
            </Typography>
            <List>
              {collaborationData[2].benifits.map((service, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={service} />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default CollaborationButton;
