import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import OurWork from './OurWork';

export const OurWorksCarousel = ({ data }) => {
    const [itemsPerPage, setItemsPerPage] = useState(1);  

    const totalItems = Object.entries(data.sub).length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const sliceData = Object.entries(data.sub);
    const [currentPage, setCurrentPage] = useState(0);
    const currentItems = sliceData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    useEffect(() => {
        const updateItemsPerPage = () => {
            const width = window.innerWidth;
            if (width < 600) {
                setItemsPerPage(1);  
            } else if (width < 960) {
                setItemsPerPage(2);  
            } else {
                setItemsPerPage(3);  
            }
        };

        updateItemsPerPage();

        window.addEventListener('resize', updateItemsPerPage);
        return () => {
            window.removeEventListener('resize', updateItemsPerPage);
        };
    }, []);

    const handleDotClick = (index) => {
        setCurrentPage(index);
    };

    return (
        <Box sx={{ padding: 2, marginRight: { xs: 1, sm: 7 }, marginLeft: { xs: 1, sm: 7 } }}>
            <Typography
                variant="h2"
                sx={{
                    textAlign: 'center',
                    color: '#1D1D1E',
                    fontWeight: { xs: 700, sm: 600 },
                    fontSize: { xs: '26px', sm: '50px' }, 
                    fontFamily: 'Poppins',
                    lineHeight: 1.2,
                }}
            >
                {data.title}
            </Typography>
            <Typography
                sx={{
                    textAlign: 'center',
                    color: '#363636',
                    fontSize: { xs: '18px', sm: '26px' },
                    fontWeight: 400,
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    marginBottom: 3
                }}
            >
                {data.description}
            </Typography>

            <Box sx={{ position: 'relative' }}>
                <Grid container justifyContent="center" spacing={3}>
                    {currentItems.map(([key, caseStudy]) => (
                        <Grid
                            item
                            xs={12}     
                            sm={6}      
                            md={4}      
                            key={caseStudy.id}
                        >
                            <OurWork caseStudy={caseStudy} />
                        </Grid>
                    ))}
                </Grid>

                {/* Dots Navigation */}
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 7 }}>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Box
                            key={index}
                            onClick={() => handleDotClick(index)}
                            sx={{
                                width: 12,
                                height: 12,
                                borderRadius: '50%',
                                backgroundColor: currentPage === index ? '#019AE5' : '#C4C4C4',
                                margin: '0 3px',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: '#019AE5',
                                },
                            }}
                        />
                    ))}
                </Box>
            </Box>

            <Box display="flex" justifyContent="center" p={2}>
                <Button
                    href='/ourwork'
                    sx={{
                        backgroundColor: '#011B6E',
                        color: 'white',
                        padding: '14px 84px',
                        fontSize: 20,
                        fontFamily: 'Poppins',
                        fontWeight: 600,
                        borderRadius: 30,
                        '&:hover': {
                            backgroundColor: '#019AE5',
                        },
                    }}
                >
                    View All
                </Button>
            </Box>
        </Box>
    );
};

export default OurWorksCarousel;
