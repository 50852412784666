import React, { useState } from "react";
import {
  Container,
  Typography,
  Breadcrumbs,
  Link,
  Box,
  Stack,
  Grid,
  Pagination,
} from "@mui/material";
import { Helmet } from "react-helmet";
import Resource from "./Resource";
import data from "../../constants/data";
import BlueContainer from '../../components/common/BlueContainer';

const Resources = () => {
  const resourcesData = data.resources;

  const [currentPage, setCurrentPage] = useState(1);
  const resourcesPerPage = 10;
  const totalResourses = Object.entries(resourcesData.sub).length;

  const indexOfLastResource = currentPage * resourcesPerPage;
  const indexOfFirstResource = indexOfLastResource - resourcesPerPage;
  const sliceData = Object.entries(resourcesData.sub).slice(
    indexOfFirstResource,
    indexOfLastResource
  );

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Helmet>
        <title>Prshete.com | Resources</title>
        <meta
          name="description"
          content="Freelancer Python Backend Developer"
        />
        <meta
          name="keywords"
          content="Freelancer Python Backend Developer, Python Remote Backend Developer"
        />
        <meta name="author" content="Purushottam Shete" />
      </Helmet>
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>

          <Typography color="text.primary">Resources</Typography>
        </Breadcrumbs>
      </Container>

      <Box
        sx={{
          bgcolor: "white",
          minHeight: "500",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          mt: 5,
          mb: 10,
        }}
      >
        <Container maxWidth="lg">
          <Stack sx={{ alignItems: "center", justifyContent: "center", mb: 2 }}>
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Poppins,sans-serif",
                fontWeight: {
                  lg: 700,
                  xs: 700,
                },
                fontSize: {
                  lg: 50,
                  xs: 30,
                },
                color: "inherit",
                textDecoration: "none",
                textShadow: 2,
                mb: 2,
              }}
            >
              {resourcesData.title}
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontFamily: "Montserrat,sans-serif",
                fontWeight: {
                  lg: 300,
                  xs: 300,
                },
                fontSize: {
                  lg: 20,
                  xs: 15,
                },
                color: "inherit",
                textDecoration: "none",
                textAlign: "center",
                px: {
                  lg: 33,
                  xs: 6,
                },
                mb: 2,
              }}
            ></Typography>
          </Stack>

          <Grid container direction="row" spacing={2}>
            {sliceData.map(([key, blog]) => (
              <Grid item xs={12} sm={12} md={4} key={blog.id}>
                <Resource blog={blog} />
              </Grid>
            ))}
          </Grid>

          <Stack
            sx={{ alignItems: "center", justifyContent: "center", mt: 5 }}
            spacing={2}
          >
            <Pagination
              count={Math.ceil(totalResourses / resourcesPerPage)}
              variant="outlined"
              page={currentPage}
              onChange={handleChange}
            />
          </Stack>
        </Container>
      </Box>
      <BlueContainer/>
    </>
  );
};

export default Resources;
