import React from 'react'
import { Container, Typography, Breadcrumbs, Link, Box, Stack, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import data from '../../constants/data';
import Industry from './Industry';
import OurWorksCarousel from '../ourworks/OurWorksCarousel';
import ResourcesCarousel from '../resources/ResourcesCarousel';

const Industries = () => {
    
    const industriesData = data.industries;
    const ourworkData = data.ourwork;
    const resourcesData = data.resources

    return (
        <>
        <Helmet>
          <title>Prshete.com | Industries </title>
          <meta name="description" content="Freelancer Python Backend Developer" />
          <meta name="keywords" content="Freelancer Python Backend Developer, Python Remote Backend Developer" />
          <meta name="author" content="Purushottam Shete" />
        </Helmet>
       
        <Container maxWidth="lg" sx={{mt:10}}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">Industries</Typography>
            </Breadcrumbs>
        </Container>
        <Box sx={{ bgcolor: 'white', minHeight: '300px', alignItems: 'center', justifyContent: 'center', display: 'flex', mt: 2, mb: 4 }}>
            <Container maxWidth="lg">
                <Stack sx={{ alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                    <Typography
                        variant="h2"
                        sx={{
                            fontFamily: 'Poppins, sans-serif',
                            fontWeight: { lg: 700, xs: 700 },
                            fontSize: { lg: 50, xs: 30 },
                            color: 'inherit',
                            textDecoration: 'none',
                            textShadow: 2,
                            mb: 2
                        }}
                    >
                      {industriesData.title}
                    </Typography>
                    <Typography
                        variant="p"
                        sx={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontWeight: { lg: 300, xs: 300 },
                            fontSize: { lg: 20, xs: 15 },
                            color: 'inherit',
                            textDecoration: 'none',
                            textAlign: 'center',
                            px: { lg: 33, xs: 6 },
                            mb: 2,
                        }}
                    >
                         {industriesData.description}
                    </Typography>
                </Stack>

                <Grid container spacing={2}>
                {Object.entries(industriesData.sub).map(([key, industry]) => (
                        <Grid item xs={12} sm={12} md={3} key={industry.id}>
                            <Industry industry={industry} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
        <OurWorksCarousel data={ourworkData}/>
        <ResourcesCarousel data={resourcesData}/>
    </>
    );
}

export default Industries;
