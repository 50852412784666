import React from "react";
import Container from "@mui/material/Container";
import { Box, Grid, Typography, Stack } from "@mui/material";
import TestimonialsButton from "./TestimonialsButton";
import testimonialsData from "../../constants/testimonialsData";

const Testimonials = () => {
  return (
    <Box
      sx={{
        bgcolor: "white",
        minHeight: "500px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        mb: 5,
      }}
    >
      <Container maxWidth="lg">
        <Stack sx={{ alignItems: "center", justifyContent: "center", mb: 2 }}>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Poppins",
              fontWeight: { xs: 700, sm: 600 },
              fontSize: { xs: '26px', sm: '50px' },
              color: "#1D1D1E",
              textDecoration: "none",
              fontStyle: 'normal',
              lineHeight: 'normal',
              mt: 2,
            }}
          >
            Testimonials
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Montserrat,sans-serif",
              fontSize: { xs: '18px', sm: '26px' },  
              fontWeight: 400,
              color: "#363636",
              textAlign: "center",
              mb: 5,
            }}
          >
            What our clients says about us
          </Typography>
        </Stack>

        <Grid container direction="row" spacing={3.875}>
          {testimonialsData.map((customer, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <TestimonialsButton customer={customer} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Testimonials;