import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import dropdownArrow from '../../assets/images/down-arrow.svg'; 

export default function SwipeableTemporaryDrawer({ data }) {
  const [state, setState] = React.useState(false);  
  const [expanded, setExpanded] = React.useState({});  

  const toggleDrawer = (value) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState(value);
  };

  const toggleExpansion = (category, event) => {
    event.stopPropagation(); 
    setExpanded((prev) => ({
      ...prev,
      [category]: !prev[category],  
    }));
  };

  return (
    <div>
      <React.Fragment>
        <IconButton onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>

        <SwipeableDrawer
          open={state}
          anchor="right"
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          sx={{
            width: 300, 
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: 300,  
              padding: '20px',  
            },
          }}
        >
          <Box
            sx={{ width: 'auto' }}
            role="presentation"
            onClick={toggleDrawer(false)} 
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {Object.keys(data).map((page) => {
                if (page === 'services' || page === 'industries') {
                  const menuItems = page === 'services' ? data.services.sub : data.industries.sub;

                  return (
                    <div key={page}>
                      <ListItemButton onClick={(event) => toggleExpansion(page, event)}>
                         <ListItemIcon>
                          <ArrowCircleRightIcon sx={{ color: "#0093E9" }} />
                        </ListItemIcon>
                        <ListItemText primary={page.charAt(0).toUpperCase() + page.slice(1)} />
                        <img
                          src={dropdownArrow}  
                          alt="dropdown arrow"
                          style={{
                            width: 16,
                            height: 16,
                            marginLeft: 8,
                            transform: expanded[page] ? 'rotate(180deg)' : 'rotate(0)',
                          }}
                        />
                      </ListItemButton>

                      {expanded[page] && (
                        <Box sx={{ pl: 2 }}>
                          {Object.entries(menuItems).map(([subKey, subValue]) => {
                            const itemUrl = `/${page}/${subValue.url}`;
                            return (
                              <ListItem key={subKey} disablePadding>
                                <ListItemButton component="a" href={itemUrl}>
                                  {/* <ListItemIcon>
                                    <ArrowCircleRightIcon sx={{ color: "#0093E9" }} />
                                  </ListItemIcon> */}
                                  <ListItemText primary={subKey} />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                        </Box>
                      )}
                    </div>
                  );
                }

                return (
                  <ListItem key={page} disablePadding>
                    <ListItemButton component="a" href={`/${data[page].url}`}>
                      <ListItemIcon>
                        <ArrowCircleRightIcon sx={{ color: "#0093E9" }} />
                      </ListItemIcon>
                      <ListItemText primary={page.charAt(0).toUpperCase() + page.slice(1)} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
