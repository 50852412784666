import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Stack,
  List,
  ListItem,
  IconButton,
  Button,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import data from "../../constants/data";


const Services = () => {
  const servicesData = data.services;

  const [hoveredService, setHoveredService] = useState(
    servicesData.sub[Object.keys(servicesData.sub)[0]]
  );

  const handleHover = (service) => {
    setHoveredService(service);
  };

  return (
    <Box
      sx={{
        marginRight:{ xs: 1, sm: 0 }, marginLeft: { xs: 1, sm: 0 },
        minHeight: "500px",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        py: 3,
      }}
    >
      <Box maxWidth="lg">
        <Stack sx={{ alignItems: "center", mb: 4 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: { xs: 700, sm: 600 },
              fontSize: { xs: '26px', sm: '50px' }, 
              color: "#000",
              textAlign: "center",
              mb: 1,
              lineHeight: "normal",
              fontStyle: "normal",
            }}
          >
            {servicesData.title}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: '18px', sm: '26px' },  
              fontWeight: 400,
              color: "#363636",
              textAlign: "center",
              lineHeight: "normal",
              fontStyle: "normal",
            }}
          >
            {servicesData.description}
          </Typography>
        </Stack>

        <Box
          sx={{
            backgroundColor: "#F9F9F9",
            borderRadius: '80px',
          }}
        >
          <Grid container spacing={4} padding={3} marginTop={5}>
            <Grid item xs={12} sm={6}>
              <Stack>
                <List>
                  {Object.entries(servicesData.sub).map(([key, service]) => (
                    <ListItem
                      key={service.id}
                      sx={{
                        cursor: "pointer",
                        padding: 3,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "100px",
                        "&:hover": {
                          bgcolor: "#E0F5FF",
                          color: "#525252",
                          borderRadius: "1100px",
                          height: "100px",
                          padding: 3,
                          "& .arrow-icon": {
                            backgroundColor: "#0BA0E0",
                            color: "white",
                          },
                        },
                      }}
                      onMouseEnter={() => handleHover(service)}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={service.icon}
                          alt={service.title}
                          style={{
                            width: 50,
                            marginRight: 10,
                          }}
                        />
                        <Typography sx={{ color: '#525252', ml: 2, fontWeight: 500, fontSize: '20px', lineHeight: '31px', fontStyle: 'normal', fontFamily: 'Poppins' }}>
                          {service.title}
                        </Typography>
                      </Box>
                      <IconButton
                        className="arrow-icon"
                        sx={{
                          color: "inherit",
                          backgroundColor: "white",
                          borderRadius: "50%",
                          padding: "8px",
                          transition:
                            "background-color 0.3s ease, color 0.3s ease",
                        }}
                      >
                        <ArrowForwardIosIcon fontSize="small" />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              {hoveredService ? (
                <Box sx={{ marginTop: 6 }}>
                  <Typography
                    sx={{
                      mb: 2,
                      flexGrow: 1,
                      fontSize: "26px",
                      fontWeight: 600,
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                    }}
                  >
                    {hoveredService.title}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography  sx={{ color: '#000', fontSize: '14px', fontWeight:300, fontFamily: 'Poppins', fontStyle: 'noraml', mb: 2, lineHeight: 'noraml' }}>
                        {hoveredService.oneline}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <img
                        src={hoveredService.image}
                        alt={hoveredService.title}
                        style={{
                          width: "100%",
                          maxWidth: "400px",
                          height: "auto",
                          borderRadius: "8px",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ textAlign: "flex-start" }}>
                    <Button
                      href={`/services/${hoveredService.url}`}
                      variant="text"
                      sx={{ textTransform: "none" }}
                    >
                      Read More <ArrowForwardIosIcon  fontSize="inherit" />
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  Hover over a service to view details
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Services;
