import React from 'react';
import Banner from './banner'
import { Helmet } from 'react-helmet';
import OurWorksCarousel from '../ourworks/OurWorksCarousel';
import data from '../../constants/data';
import Collaboration from './Collaboration';
import Services from './Services';
import ResourcesCarousel from '../resources/ResourcesCarousel';
import Testimonials from './Testimonials';
import BlueContainer from '../common/BlueContainer';
// import ServicesSection from './ServicesSection';

const Home = () => {
    const ourworkData = data.ourwork;
    const resourcesData = data.resources
    return (
        <>
            <Helmet>
                <title>PRSTech.io | Home </title>
                <meta name="description" content="PRSTech Python-specialised Software Services" />
                <meta name="keywords" content="PRSTech Python-specialised Software Services, Python Developers on Contract" />
                <meta name="author" content="PRShete Technologies Pvt. Ltd." />
            </Helmet>
            <Banner />
            <Collaboration/>
            <Services/>
            {/* <ServicesSection/> */}
            <OurWorksCarousel data={ourworkData}/> 
            <ResourcesCarousel data={resourcesData}/>
            <Testimonials/>
            <BlueContainer/>
            
        </>
    );
}

export default Home;