import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {
  Container,
  Typography,
  Breadcrumbs,
  Link,
  Box,
  Stack,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import BlueContainer from "../common/BlueContainer";
import data from "../../constants/data";
import benefitsListIcon from '../../assets/images/benifits-list-icon.svg'

const OurWorkPage = () => {
  const { id } = useParams();

  const OurWorkData = data.ourwork.sub;
  const caseStudy = Object.values(OurWorkData).find((item) => item.url === id);

  return (
    <>
      <Helmet>
        <title>Prshete.com | Our Work </title>
        <meta
          name="description"
          content="Freelancer Python Backend Developer"
        />
        <meta
          name="keywords"
          content="Freelancer Python Backend Developer, Python Remote Backend Developer"
        />
        <meta name="author" content="Purushottam Shete" />
      </Helmet>

      {/* <Container maxWidth="lg" sx={{mt:10}}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Link underline="hover" color="inherit" href="/ourwork">
                    Our Work
                </Link>
                <Typography color="text.primary">{caseStudy.title}</Typography>
            </Breadcrumbs>
        </Container> */}

      <Box
        sx={{
          marginRight: { xs: 1, sm: 7 },
          marginLeft: { xs: 1, sm: 7 },
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <Box
          sx={{
            position: "relative",
            background: `url(${caseStudy.banner}) lightgray 50% / cover no-repeat`,
            width: "100%",
            height: { xs: 280, sm: 637 },
            justifyContent: "flex-start",
            textAlign: "left",
            paddingLeft: 3,
            paddingTop: { xs: 2, sm: 5 },
          }}
        >
            {/* <Typography
            variant="h2"
            sx={{
              position: "absolute",
              top: "20%",
              left: 3,
              fontFamily: "Poppins",
              fontWeight: 300,
              fontSize: { xs: 20, sm: 32 },
              color: "#5E5E5E",
              zIndex: 1,
            }}
          >
            {caseStudy.text}
          </Typography> */}
          {/* <Typography
            variant="h1"
            sx={{
              position: "absolute",
              top: "28%",
              left: 3,
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: { xs: 20, sm: 65 },
              color: "#191919",
              zIndex: 1,
            }}
          >
            {caseStudy.title}
          </Typography> */}

        </Box>
        <Box
          sx={{
            bgcolor: "white",
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            mt: 2,
            mb: 4,
          }}
        >
          <Box  sx={{
           
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            mb: 4,
          }}>
            <Stack spacing={4} sx={{ mb: 2 }}>
            <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontWeight: 600,
                            fontSize: { lg: 28, xs: 20 },
                            color: '#1D1D1E',
                        }}
                    >
                        {caseStudy.title}
             </Typography>
              <Grid container spacing={0}>
                <Grid item xs={12} md={9}>
                <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: { xs: 15, sm: 20 },
                  color: "#000",
                  textAlign: "flex-start",
                  lineHeight: '160%',
                  fontStyle: 'normal',
                  mb:7
                }}
              >
                {caseStudy.description}
              </Typography>
                  <Box>
                    <Typography sx={{ fontFamily: 'Poppins', color: '#1D1D1E', fontSize: 24, fontWeight: 600, mb: 2 }}>
                      Challenges
                    </Typography>
                    <Typography sx={{color:'#363636',fontSize: 16, fontWeight:400, fontFamily: 'Poppins' ,mb: 2 }}>
                      {caseStudy.challengaes.body}
                    </Typography>
                    <List>
                      {caseStudy.challengaes.bullets.map((challenge, index) => (
                       <ListItem key={index}>
                       <ListItemIcon>
                         <CheckCircleIcon color="primary" />
                       </ListItemIcon>
                       {/* <Typography sx={{color:'#000',fontSize: 16, fontWeight:300, fontFamily: 'Poppins' ,mb: 2, lineHeight: '160%' }}>{solution}</Typography> */}
                       <ListItemText sx={{color:'#000',fontSize: 16, fontWeight:300, fontFamily: 'Poppins', lineHeight: '160%' }} primary={challenge} />
                       </ListItem>
                      ))}
                     </List>
                  </Box>

                  <Box sx={{ mt: 4 }}>
                    <Typography sx={{ fontFamily: 'Poppins', color: '#1D1D1E', fontSize: 24, fontWeight: 600, mb: 2 }}>
                      Solution
                    </Typography>
                    <Typography sx={{color:'#363636',fontSize: 16, fontWeight:400, fontFamily: 'Poppins' ,mb: 2 }}>
                      {caseStudy.solution.body}
                    </Typography>
                    <List>
                      {caseStudy.solution.bullets.map((solution, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <CheckCircleIcon color="primary" />
                          </ListItemIcon>
                          {/* <Typography sx={{color:'#000',fontSize: 16, fontWeight:300, fontFamily: 'Poppins' ,mb: 2, lineHeight: '160%' }}>{solution}</Typography> */}
                          <ListItemText sx={{color:'#000',fontSize: 16, fontWeight:300, fontFamily: 'Poppins', lineHeight: '160%' }} primary={solution} />
                          </ListItem>
                      ))}
                   </List>

                  </Box>
                </Grid>

                <Grid item xs={12} md={3} spacing={4} >
                  <Box sx={{backgroundColor: '#F9F9F9', height: 'auto', width: '100%', borderRadius: '23px'}}>
                  <Box sx={{padding: 3}}>
                    <Typography sx={{ fontFamily: 'Poppins', color: '#1D1D1E', fontSize: 24, fontWeight: 600, mb: 1 }}>
                      Industry
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      {caseStudy.benifits.industry}
                    </Typography>
                  </Box>

                  <Box sx={{  borderTop: '2px solid #969595' }}>
                    <List>
                      {caseStudy.benifits.bullets.map((benefit, index) => (
                        <ListItem key={index}>
                          <img
                            src={benefitsListIcon}
                            alt="benefitsListIcon"
                            style={{ width: 40, height: 40, marginRight: 15 }}
                          />
                        {/* <Typography sx={{color:'#000',fontSize: 16, fontWeight:300, fontFamily: 'Poppins' ,mb: 2, lineHeight: '160%' }}>{solution}</Typography> */}
                        <ListItemText sx={{color:'#000',fontSize: 16, fontWeight:300, fontFamily: 'Poppins', lineHeight: '160%' }} primary={benefit} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </Box>
      </Box>
      <BlueContainer/>
    </>
  );
};

export default OurWorkPage;
