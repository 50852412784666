import React,{useState} from "react";
import { Box, Button, Typography } from "@mui/material";
import Slider from "react-slick"; // Import the react-slick Slider component
import banerImage from "../../assets/images/home-overlay-image.png";
import subtract from "../../assets/images/subtract.png";
import mobileSubtract from "../../assets/images/Subtract-mobile.png";
import mobileBanerImage from "../../assets/images/mobileoverlay.png";
import { useTranslation } from "react-i18next";
import bookCallIcon from "../../assets/images/book-call-icon.svg";
import banner2 from '../../assets/images/banner2.png';
import banner3 from '../../assets/images/banner3.png';


const Banner = () => {
  const { t } = useTranslation();
  const homeData = t("home", { returnObjects: true });
  const [currentPage, setCurrentPage] = useState(0); 

  const settings = {
    dots: true,           
    infinite: true,       
    speed: 500,           
    slidesToShow: 1,      
    slidesToScroll: 1,    
    autoplay: true,       
    autoplaySpeed: 2500,  
    beforeChange: (current, next) => {
      setCurrentPage(next); 
    },
    customPaging: (i) => {
      return (
        <Box
          sx={{
            width: 12,
            height: 12,
            borderRadius: '50%',
            backgroundColor: currentPage === i ? '#019AE5' : '#C4C4C4',
            margin: '0 5px',
            cursor: 'pointer',
            transition: 'background-color 0.2.5s ease', 
            '&:hover': {
              backgroundColor: '#019AE5',
            },
          }}
        />
      );
    },
  };

  return (
    <Box sx={{ marginRight: { xs: 1, sm: 7 }, marginLeft: { xs: 1, sm: 7 }, marginTop: 10,  height: { xs: 320, sm: 677 },   }}>
      <Box sx={{ display: { xs: "none", sm: "block" },}}>
      <Slider {...settings}>
        {/* Box 1 */}
        <Box sx={{ position: "relative", height: { xs: 280, sm: 637 }, justifyContent: "center", textAlign: "flex-start" }}>
          {/* Desktop Box */}
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
              width: "100%",
              height: "100%",
              justifyContent: "center",
              textAlign: "flex-start",
              position: "relative",
            }}
          >
            <img
              src={subtract}
              alt="Desktop Subtract"
              style={{
                position: "absolute",
                width: "100%",
                height: 594,
                top: 0,
                left: 0,
              }}
            />

            <img
              src={banerImage}
              alt="Desktop Banner"
              style={{
                position: "absolute",
                width: 356,
                height: 570,
                top: 23,
                left: 888,
              }}
            />

            <Typography
              sx={{
                position: "absolute",
                top: 150,
                left: 63,
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: 65,
                color: "#191919",
              }}
            >
              {homeData.banner[0].title}
            </Typography>

            <Typography
              sx={{
                position: "absolute",
                top: 94,
                left: 63,
                fontFamily: "Poppins",
                fontWeight: 300,
                fontSize: 36,
                color: "#000000",
              }}
            >
              {homeData.banner[0].caption}
            </Typography>

            <Typography
              sx={{
                width: "60%",
                position: "absolute",
                top: 310,
                left: 63,
                fontFamily: "Poppins",
                fontWeight: "normal",
                fontSize: 24,
                color: "black",
              }}
            >
              <b>{homeData.banner[0].question}</b><br/>
              {homeData.banner[0].description}
            </Typography>

            <Button
              href="/bookcall"
              variant="contained"
              sx={{
                position: "absolute",
                top: 500,
                left: 63,
                width: 251,
                height: 66,
                backgroundColor: "#011b6e",
                borderRadius: 33,
                fontFamily: "Poppins, Helvetica",
                fontWeight: "semiBold",
                fontSize: 20,
                color: "white",
              }}
            >
              BOOK CALL
              <img
                src={bookCallIcon}
                alt="book-call arrow"
                style={{ width: 16, height: 16, marginLeft: 10 }}
              />
            </Button>
          </Box>
        </Box>
        {/* Box 2 */}
        <Box sx={{ position: "relative", height: { xs: 280, sm: 637 }, justifyContent: "center", textAlign: "flex-start" }}>
          {/* Desktop Box */}
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
              width: "100%",
              height: "100%",
              justifyContent: "center",
              textAlign: "flex-start",
              position: "relative",
            }}
          >
            <img
              src={subtract}
              alt="Desktop Subtract"
              style={{
                position: "absolute",
                width: "100%",
                height: 594,
                top: 0,
                left: 0,
              }}
            />

                <img
              src={banner2}
              alt="Desktop Banner"
              style={{
                position: "absolute",
                width: 556,
                height: 570,
                top: 23,
                left: 800,
              }}
            />

            <Typography
               sx={{
                position: "absolute",
                top: 150,
                left: 63,
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: 65,
                color: "#191919",
              }}
            >
              {homeData.banner[1].title}
            </Typography>

            <Typography
              sx={{
                position: "absolute",
                top: 94,
                left: 63,
                fontFamily: "Poppins",
                fontWeight: 300,
                fontSize: 36,
                color: "#000000",
              }}
            >
              {homeData.banner[1].caption}
            </Typography>

            <Typography
              sx={{
                width: "60%",
                position: "absolute",
                top: 310,
                left: 63,
                fontFamily: "Poppins, Helvetica",
                fontWeight: "normal",
                fontSize: 24,
                color: "black",
              }}
            >
               <b>{homeData.banner[1].question}</b><br/>
             {homeData.banner[1].description}
            </Typography>

            <Button
              href="/bookcall"
              variant="contained"
              sx={{
                position: "absolute",
                top: 500,
                left: 63,
                width: 251,
                height: 66,
                backgroundColor: "#011b6e",
                borderRadius: 33,
                fontFamily: "Poppins, Helvetica",
                fontWeight: "semiBold",
                fontSize: 20,
                color: "white",
              }}
            >
              BOOK CALL
              <img
                src={bookCallIcon}
                alt="book-call arrow"
                style={{ width: 16, height: 16, marginLeft: 10 }}
              />
            </Button>
          </Box>
        </Box>
        {/* Box 3 */}
        <Box sx={{ position: "relative", height: { xs: 280, sm: 637 }, justifyContent: "center", textAlign: "flex-start" }}>
          {/* Desktop Box */}
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
              width: "100%",
              height: "100%",
              justifyContent: "center",
              textAlign: "flex-start",
              position: "relative",
            }}
          >
            <img
              src={subtract}
              alt="Desktop Subtract"
              style={{
                position: "absolute",
                width: "100%",
                height: 594,
                top: 0,
                left: 0,
              }}
            />

            <img
              src={banner3}
              alt="Desktop Banner"
              style={{
                position: "absolute",
                width: 556,
                height: 570,
                top: 23,
                left: 800,
              }}
            />

            <Typography
               sx={{
                position: "absolute",
                top: 150,
                left: 63,
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: 65,
                color: "#191919",
              }}
            >
               {homeData.banner[2].title}
            </Typography>

            <Typography
               sx={{
                width: '50%',
                position: "absolute",
                top: 70,
                left: 63,
                fontFamily: "Poppins",
                fontWeight: 300,
                fontSize: 36,
                color: "#000000",
              }}
            >
              {homeData.banner[2].caption}
            </Typography>

            <Typography
              sx={{
                width: "60%",
                position: "absolute",
                top: 310,
                left: 63,
                fontFamily: "Poppins, Helvetica",
                fontWeight: "normal",
                fontSize: 24,
                color: "black",
              }}
            >
               <b>{homeData.banner[2].question}</b><br/>
               {homeData.banner[2].description}
            </Typography>

            <Button
              href="/bookcall"
              variant="contained"
              sx={{
                position: "absolute",
                top: 500,
                left: 63,
                width: 251,
                height: 66,
                backgroundColor: "#011b6e",
                borderRadius: 33,
                fontFamily: "Poppins, Helvetica",
                fontWeight: "semiBold",
                fontSize: 20,
                color: "white",
              }}
            >
              BOOK CALL
              <img
                src={bookCallIcon}
                alt="book-call arrow"
                style={{ width: 16, height: 16, marginLeft: 10 }}
              />
            </Button>
          </Box>
        </Box>
      </Slider>
      </Box>
          {/* Mobile Box (Only visible on xs screens) */}
        <Box sx={{marginBottom: 5,  display: { xs: "block", sm: "none" },}}>  
        <Slider {...settings}>
        <Box sx={{ position: "relative", height: { xs: 280, sm: 637 }, justifyContent: "center", textAlign: "flex-start" }}>
        <Box
          sx={{
            position: "relative",
            display: { xs: "block", sm: "none" },
            width: "100%",
            height: "auto",
            textAlign: "flex-start",
          }}
        >
          <img
            src={mobileSubtract}
            alt="Mobile Subtract"
            style={{
              position: "absolute",
              width: "100%",
              height: "auto",
              top: 0,
              left: 0,
            }}
          />

          <img
            src={mobileBanerImage}
            alt="Mobile Banner"
            style={{
              position: "absolute",
              width: "35%",
              height: "auto",
              top: 20,
              left: "62%",
            }}
          />

          <Typography
            variant="h2"
            sx={{
              position: "absolute",
              top: 40,
              left: 30,
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: "12px",
              color: "#000",
              lineHeight: "normal",
              maxWidth: "80%",
            }}
          >
           {homeData.banner[0].caption}
          </Typography>

          <Typography
            variant="h1"
            sx={{
              position: "absolute",
              top: 55,
              left: 30,
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: "20px",
              color: "#191919",
              lineHeight: "normal",
              maxWidth: "80%",
            }}
          >
            {homeData.banner[0].title}
          </Typography>

          <Typography
            sx={{
              width: "55%",
              position: "absolute",
              top: 105,
              left: 30,
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: "12px",
              color: "#000",
              lineHeight: "normal",
            }}
          >
            {homeData.banner[0].description}
          </Typography>

          <Button
          href="/bookcall"
            variant="contained"
            sx={{
              position: "absolute",
              top: 200,
              left: 30,
              width: "40%",
              height: "auto",
              backgroundColor: "#011B6E",
              borderRadius: "20.5px",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "15px",
              color: "white",
              padding: "8px 12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            BOOK CALL
            <img
              src={bookCallIcon}
              alt="book-call arrow"
              style={{ width: 16, height: 16, marginLeft: 10 }}
            />
          </Button>
        </Box>
        </Box>
        <Box sx={{ position: "relative", height: { xs: 280, sm: 637 }, justifyContent: "center", textAlign: "flex-start" }}>
        <Box
          sx={{
            position: "relative",
            display: { xs: "block", sm: "none" },
            width: "100%",
            height: "auto",
            textAlign: "flex-start",
          }}
        >
          <img
            src={mobileSubtract}
            alt="Mobile Subtract"
            style={{
              position: "absolute",
              width: "100%",
              height: "auto",
              top: 0,
              left: 0,
            }}
          />

          <img
            src={banner2}
            alt="Mobile Banner"
            style={{
              position: "absolute",
              width: "45%",
              height: "auto",
              top: 20,
              left: "60%",
            }}
          />

          <Typography
            variant="h2"
            sx={{
              position: "absolute",
              top: 40,
              left: 30,
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: "12px",
              color: "#000",
              lineHeight: "normal",
              maxWidth: "80%",
            }}
          >
             {homeData.banner[1].caption}
          </Typography>

          <Typography
            variant="h1"
            sx={{
              position: "absolute",
              top: 55,
              left: 30,
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: "20px",
              color: "#191919",
              lineHeight: "normal",
              maxWidth: "80%",
            }}
          >
            {homeData.banner[1].title}
          </Typography>

          <Typography
            sx={{
              width: "55%",
              position: "absolute",
              top: 105,
              left: 30,
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: "12px",
              color: "#000",
              lineHeight: "normal",
            }}
          >
             {homeData.banner[1].description}
          </Typography>

          <Button
          href="/bookcall"
            variant="contained"
            sx={{
              position: "absolute",
              top: 200,
              left: 30,
              width: "40%",
              height: "auto",
              backgroundColor: "#011B6E",
              borderRadius: "20.5px",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "15px",
              color: "white",
              padding: "8px 12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            BOOK CALL
            <img
              src={bookCallIcon}
              alt="book-call arrow"
              style={{ width: 16, height: 16, marginLeft: 10 }}
            />
          </Button>
        </Box>
        </Box>
        <Box sx={{ position: "relative", height: { xs: 280, sm: 637 }, justifyContent: "center", textAlign: "flex-start" }}>
        <Box
          sx={{
            position: "relative",
            display: { xs: "block", sm: "none" },
            width: "100%",
            height: "auto",
            textAlign: "flex-start",
          }}
        >
          <img
            src={mobileSubtract}
            alt="Mobile Subtract"
            style={{
              position: "absolute",
              width: "100%",
              height: "auto",
              top: 0,
              left: 0,
            }}
          />

          <img
            src={banner3}
            alt="Mobile Banner"
            style={{
              position: "absolute",
              width: "45%",
              height: "auto",
              top: 23,
              left: "60%",
            }}
          />

          <Typography
            variant="h2"
            sx={{
              width: '50%',
              position: "absolute",
              top: 20,
              left: 30,
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: "12px",
              color: "#000",
              lineHeight: "normal",
              maxWidth: "80%",
            }}
          >
             {homeData.banner[2].caption}
          </Typography>

          <Typography
            variant="h1"
            sx={{
              position: "absolute",
              top: 55,
              left: 30,
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: "20px",
              color: "#191919",
              lineHeight: "normal",
              maxWidth: "80%",
            }}
          >
            {homeData.banner[2].title}
          </Typography>

          <Typography
            sx={{
              width: "55%",
              position: "absolute",
              top: 105,
              left: 30,
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: "12px",
              color: "#000",
              lineHeight: "normal",
            }}
          >
            {homeData.banner[2].description}
          </Typography>

          <Button
          href="/bookcall"
            variant="contained"
            sx={{
              position: "absolute",
              top: 200,
              left: 30,
              width: "40%",
              height: "auto",
              backgroundColor: "#011B6E",
              borderRadius: "20.5px",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "15px",
              color: "white",
              padding: "8px 12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            BOOK CALL
            <img
              src={bookCallIcon}
              alt="book-call arrow"
              style={{ width: 16, height: 16, marginLeft: 10 }}
            />
          </Button>
        </Box>
        </Box>
        </Slider>
        </Box>
        </Box>
  );
};

export default Banner;
