import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Breadcrumbs,
  Link,
  Stack,
  Box,
} from "@mui/material";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import data from "../../constants/data";
import BlueContainer from "../common/BlueContainer";

const ResourcePage = () => {
  const [markdown, setMarkdown] = useState("");
  const { id } = useParams();

  const resourcesData = data.resources.sub;
  const blog = Object.values(resourcesData).find((item) => item.url === id);

  useEffect(() => {
    if (blog) {
      fetch(blog.file) // Using the updated path
        .then((res) => res.text())
        .then((text) => setMarkdown(text))
        .catch((error) =>
          console.error("Error fetching the markdown file:", error)
        );
    }
  }, [blog]);

  if (!blog) {
    return <Typography variant="h5">Resource not found.</Typography>;
  }

  return (
    <>
      <Helmet>
        <title>Prshete.com | Resources</title>
        <meta
          name="description"
          content="Freelancer Python Backend Developer"
        />
        <meta
          name="keywords"
          content="Freelancer Python Backend Developer, Python Remote Backend Developer"
        />
        <meta name="author" content="Purushottam Shete" />
      </Helmet>
      {/* <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          <Link underline="hover" color="inherit" href="/resources">
            Resources
          </Link>
          <Typography color="text.primary">{blog.title}</Typography>
        </Breadcrumbs>
      </Container> */}

      <Box
        sx={{
          marginRight: { xs: 1, sm: 7 },
          marginLeft: { xs: 1, sm: 7 },

          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <Box
          sx={{
            position: "relative",
            background: `url(${blog.banner}) white 50% / cover no-repeat`,
            width: "100%",
            height: { xs: 280, sm: 637 },
            justifyContent: "flex-start",
            textAlign: "left",
            paddingLeft: 3,
            paddingTop: { xs: 2, sm: 5 },
          }}
        >
          {/* <Typography
            variant="h1"
            sx={{
              position: "absolute",
              top: "13%",
              left: "3%",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: { xs: 20, sm: 44 },
              color: "#191919",
              zIndex: 1,
              lineHeight: '77px'
            }}
          >
            Blog
          </Typography>  */}
        </Box>
        <Box
          sx={{
            bgcolor: "white",
            minHeight: "300px",
            display: 'flex',
            justifyContent: "center",
            mt: 2,
            mb: 4,
          }}
        >
          
                <Container>
              {/* <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  fontSize: { lg: 28, xs: 20 },
                  color: "#1D1D1E",
                }}
              >
                {blog.title}
              </Typography> */}
             
              <Box sx={{ mb: 2 }}>
                <ReactMarkdown>{markdown}</ReactMarkdown>
              </Box>
            </Container>
          </Box>
        </Box>
      <BlueContainer />
    </>
  );
};

export default ResourcePage;
