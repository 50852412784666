import React from "react";
import { Container, Typography, Breadcrumbs, Link } from "@mui/material";
import { Helmet } from "react-helmet";
import data from "../../constants/data";
import ServicesCard from "./ServicesCard";
import OurWorksCarousel from "../ourworks/OurWorksCarousel";
import ResourcesCarousel from "../resources/ResourcesCarousel";


const Services = () => {

    const servicesData = data.services;
    const ourworkData = data.ourwork;
    const resourcesData = data.resources
  
  return (
    <>
      <Helmet>
        <title>Prshete.com | Services </title>
        <meta
          name="description"
          content="Freelancer Python Backend Developer"
        />
        <meta
          name="keywords"
          content="Freelancer Python Backend Developer, Python Remote Backend Developer"
        />
        <meta name="author" content="Purushottam Shete" />
      </Helmet>
     
        <Container maxWidth="lg" sx={{ mt: 10 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Typography color="text.primary">Services</Typography>
          </Breadcrumbs>
        </Container>
        <ServicesCard
          title={servicesData.title}
          description={servicesData.description}
          data={servicesData.sub}
        />
        <OurWorksCarousel data={ourworkData}/>
        <ResourcesCarousel data={resourcesData}/>
    
    </>
  );
};

export default Services;
