import React from 'react';
import Container from "@mui/material/Container";

const NoPage = () => {
    return (
        <>
        <Container maxWidth="lg" sx={{mt:10}}>
            <h1>404 Page Not Found</h1>
        </Container>
        </>
    );
}

export default NoPage;