import {
  teamAugmentation,
  techConsultancy,
  softwareDevelopmentOutsourcing,
} from "./images";

const collaborationData = [
  {
    title: "Software Development Outsourcing",
    image: softwareDevelopmentOutsourcing,
    text: "Maximize efficiency and reduce costs by leveraging specialized global talent through software development outsourcing.",
    benifits: [
      "Product Design & Planning",
      "Agile Development",
      "Product Deployment",
      "Support and Maintenance",
    ],
  },
  {
    title: "Team Augmentation",
    image: teamAugmentation,
    text: "Enhance your team's capabilities and scale quickly with expert talent, avoiding recruiting overhead with team augmentation.",
    benifits: [
      "Scalability",
      "Prompt Communication",
      "Project Management",
      "Quality Delivery",
    ],
  },
  {
    title: "Tech Consultancy",
    image: techConsultancy,
    text: "Unlock your business's potential with strategic insights and innovative solutions from expert tech consultancy services.",
    benifits: [
      "Architecture Consultancy",
      "Data Consultancy",
      "Research & Development",
      "Rapid Prototyping ",
    ],
  },
];

export default collaborationData;
