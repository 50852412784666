import {React, useState} from 'react';
import { Box, Container, Typography, Breadcrumbs, Link , Grid} from '@mui/material';
import { Helmet } from 'react-helmet';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import teamData from '../../constants/teamData';
import Team from './Team';

const AboutUs = () => {
    const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  return (
    <>
        <Helmet>
            <title>PRSTech.io | About Us</title>
            <meta name="description" content="Contact Us PRSTech.io" />
            <meta name="keywords" content="Contact Us PRSTech.io" />
            <meta name="author" content="PRShete Technologies Pvt. Ltd." />
        </Helmet>
        <Container maxWidth="lg" sx={{ mt: 10 }}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">Home</Link>
                <Typography color="text.primary">About</Typography>
            </Breadcrumbs>
        </Container>
        <Box sx={{ bgcolor: 'white', height: { lg: '500px', xs: '650px' }, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Container maxWidth="lg">
                <Typography variant="h1" sx={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 900, fontSize: { lg: 40, xs: 30 }, mt: 1, mb: 1 }}>
                    About Us
                </Typography>
                <Typography variant="body1" sx={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 400, fontSize: { lg: 20, xs: 15 }, mt: 2, mb: 2 }}>
                    At PRSTech, we are dedicated to delivering innovative IT solutions tailored to meet the unique needs of our clients. With a focus on excellence and customer satisfaction, we strive to be the trusted partner that businesses can rely on for all their technology needs.
                    <br /><br />
                    Founded in <b>2016</b>, PRSTech has quickly grown into a leading provider of IT services, serving clients around the globe. Our team of skilled professionals brings together a wealth of experience and expertise in various areas of technology, allowing us to deliver cutting-edge solutions that drive business growth and success.
                    <br /><br />
                    Our commitment to excellence extends beyond just delivering great technology solutions. We also prioritize customer service and satisfaction, ensuring that our clients receive the highest level of support and attention throughout their engagement with us. With PRSTech, you can trust that you're not just getting a vendor – <b>you're getting a partner invested in your success</b>.
                </Typography>
            </Container>
        </Box>

        <Box sx={{ bgcolor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700, fontSize: { lg: 30, xs: 24 }, mb: 1 }}>
                            Mission
                        </Typography>
                        <Box component="img" src="https://static.vecteezy.com/system/resources/thumbnails/021/592/384/small_2x/mission-wooden-signpost-with-one-arrow-sky-with-clouds-photo.jpg" alt="Mission" sx={{ width: '150px', height: '150px', borderRadius: '8px', mb: 2 }} />
                        <Typography variant="body1" sx={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 400, fontSize: { lg: 20, xs: 15 }, mb: 2 }}>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700, fontSize: { lg: 30, xs: 24 }, mb: 1 }}>
                            Vision
                        </Typography>
                        <Box component="img" src="https://static.vecteezy.com/system/resources/thumbnails/029/316/698/small/target-concept-of-business-and-personnel-in-a-company-wooden-dolls-standing-around-dart-board-and-arrows-for-creating-and-targeting-business-objectives-marketing-solutions-photo.jpg" alt="Vision" sx={{ width: '150px', height: '150px', borderRadius: '8px', mb: 2 }} />
                        <Typography variant="body1" sx={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 400, fontSize: { lg: 20, xs: 15 }, mb: 2 }}>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>

        <Box sx={{ bgcolor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 5 }}>
            <Container maxWidth="lg">
                <Typography variant="h4" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700, fontSize: { lg: 30, xs: 24 }, mb: 2 }}>
                    Team
                </Typography>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="Team tabs">
                                <Tab label="Tech" value="1" />
                                <Tab label="Leadership" value="2" />
                                <Tab label="Advisors" value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Grid container spacing={2}>
                                {Object.entries(teamData.tech).map(([key, emp]) => (
                                    <Grid item xs={12} sm={6} md={3} key={emp.id}>
                                        <Team emp={emp} />
                                    </Grid>
                                ))}
                            </Grid>
                        </TabPanel>
                        <TabPanel value="2">
                            <Grid container spacing={2}>
                                {Object.entries(teamData.leadership).map(([key, emp]) => (
                                    <Grid item xs={12} sm={6} md={3} key={emp.id}>
                                        <Team emp={emp} />
                                    </Grid>
                                ))}
                            </Grid>
                        </TabPanel>
                        <TabPanel value="3">
                            <Grid container spacing={2}>
                                {Object.entries(teamData.advisors).map(([key, emp]) => (
                                    <Grid item xs={12} sm={6} md={3} key={emp.id}>
                                        <Team emp={emp} />
                                    </Grid>
                                ))}
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Container>
        </Box>
    </>
);
}

export default AboutUs;