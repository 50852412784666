import React from 'react';
import { Box, Typography, Rating } from '@mui/material';

const TestimonialsButton = ({ customer }) => {
  return (
    <Box sx={{
      position: 'relative',
      backgroundColor: 'background.paper',
      padding: 4,
      borderRadius: 2,
      boxShadow: '0px 94px 200px 0px rgba(21, 21, 21, 0.15)',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Box
        component="img"
        src={customer.image}
        alt={customer.name}
        sx={{
          position: 'absolute',
          top: -25,  
          left: 10,  
          width: 80,
          height: 80,
          borderRadius: '50%',
          objectFit: 'cover',
          border: '4px solid white',  
        }}
      />

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Rating
              value={customer.rating}
              readOnly
              size="small"
              sx={{
                '& .MuiRating-iconFilled': {
                  color: '#3E66DF',  
                },
                '& .MuiRating-iconEmpty': {
                  color: '#BDBDBD',  
                },
              }}
            />
          </Box>
        </Box>

      <Typography sx={{ fontFamily: 'Inter', fontSize: 20, fontWeight: 400, marginTop: 4, fontStyle: 'normal', lineHeight: 'normal' }}>
        {customer.review}
      </Typography>

      <Box sx={{ marginTop: 2 }}>
        <Typography  sx={{fontFamily: 'Manrope', fontWeight: 800, fontSize: 14}}>
          {customer.name}
        </Typography>
        <Typography sx={{fontSize: 10, fontWeight: 400, fontFamily: 'Manrope', }}>
          {customer.designation}, {customer.company}
        </Typography>
      </Box>
    </Box>
  );
};

export default TestimonialsButton;
