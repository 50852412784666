const teamData = {
    "tech": {
      "1": {
        "id": "1",
        "name": "Sujit sanap",
        "image":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0VYUSDVV7NJRH3BPqHJrNslN4GVMeowwe37lIi_z6nTMS-SPBqdDEeJtnPeuontevchs&usqp=CAU",
        "linkedin": "https://www.linkedin.com/"
      },
      "2": {
        "id": "2",
        "name": "Amit Patil",
        "image":"https://chicagophotovideo.com/wp-content/uploads/2017/10/ezgif.com-webp-to-jpg-14.jpg",
        "linkedin": "https://www.linkedin.com/"
      },
      "3": {
        "id": "3",
        "name": " Akshay Patil",
        "image":"https://cdn.prod.website-files.com/639ff8596ae419fae300b099/641017314cc67fbb88c517a7_good-linkedin-profile-photo-right-expression-1000x1000.jpeg",
        "linkedin": "https://www.linkedin.com/"
      },
      "4": {
        "id": "4",
        "name": "Kiran Bangar",
        "image":"https://corporate.bestbuy.com/wp-content/uploads/2019/12/matt_ville_blog_header_v01.jpg",
        "linkedin": "https://www.linkedin.com/"
      },
      "5": {
        "id": "5",
        "name": "Kiran Bangar",
        "image":"https://corporate.bestbuy.com/wp-content/uploads/2019/12/matt_ville_blog_header_v01.jpg",
        "linkedin": "https://www.linkedin.com/"
      }
    },
    "leadership": {
        "1": {
          "id": "1",
          "name": "Sujit sanap",
          "image":"https://media.licdn.com/dms/image/D4D12AQGsWiQQo-hEew/article-cover_image-shrink_720_1280/0/1705940048112?e=2147483647&v=beta&t=Dm3TYa8aaImrrYHEksUYyCuPe0mRjKNlrKcNMnKjlXc",
          "linkedin": "https://www.linkedin.com/in/alicejohnson"
        },
        "2": {
          "id": "2",
          "name": "Amit Patil",
          "image":"https://imgv3.fotor.com/images/ai-headshot-generator/AI-generated-headshot-of-a-female-in-dark-grey-attire-standing-her-back-to-a-builing-view-window-by-Fotor-AI-LinkedIn-photo-generator.jpg",
          "linkedin": "https://www.linkedin.com/in/bobsmith"
        },
        "3": {
          "id": "3",
          "name": " Akshay Patil",
          "image":"https://knowledgeenthusiast.com/wp-content/uploads/2022/04/pexels-photo-6694422.jpeg",
          "linkedin": "https://www.linkedin.com/in/charliebrown"
        },
        "4": {
          "id": "4",
          "name": "Kiran Bangar",
          "image":"https://knowledgeenthusiast.com/wp-content/uploads/2022/04/pexels-photo-6694422.jpeg",
          "linkedin": "https://www.linkedin.com/in/dianaprince"
        }
      },
      "advisors": {
        "1": {
          "id": "1",
          "name": "Sujit sanap",
          "image":"https://www.retratosbarcelona.com/wp-content/uploads/2022/09/Linkedin-photo-shoot-banner-1080x675.jpg",
          "linkedin": "https://www.linkedin.com/in/alicejohnson"
        },
        "2": {
          "id": "2",
          "name": "Amit Patil",
          "image":"https://media.licdn.com/dms/image/C4E12AQE1ohEvD1HA2w/article-cover_image-shrink_600_2000/0/1520103088178?e=2147483647&v=beta&t=RCQJgxM8r7i9u2rTJhXHw1P_R2aLbt8l2avGIegJZpQ",
          "linkedin": "https://www.linkedin.com/in/bobsmith"
        },
        "3": {
          "id": "3",
          "name": " Akshay Patil",
          "image":"https://knowledgeenthusiast.com/wp-content/uploads/2022/04/pexels-photo-6694422.jpeg",
          "linkedin": "https://www.linkedin.com/in/charliebrown"
        },
        "4": {
          "id": "4",
          "name": "Kiran Bangar",
          "image":"https://imgv3.fotor.com/images/ai-headshot-generator/AI-generated-LinkedIn-profile-picture--of-a-smiling-male-in-the-light-brown-business-suit-with-his-back-to-the-window-from-Fotor.jpg",
          "linkedin": "https://www.linkedin.com/in/dianaprince"
        }
      }
  }

export default teamData;