import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';

const BlueContainer = () => {
  return (
    <section style={{ backgroundColor: '#019ae5', display: 'flex', minHeight: '184px', padding: '20px' }}>
      <Container 
      maxWidth="xxl"
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', sm: 'space-between' },
          flexDirection: { xs: 'column', sm: 'row' },
          marginLeft: {xs: 5, sm: 'none' } 
        }}
      >  
        <Box 
          sx={{ 
            flexGrow: 1, 
            display: 'flex', 
            alignItems: 'center',
            display: { xs: 'none', sm: 'flex' }, 
          }}
        >
          <Typography 
            sx={{
              fontSize: 45, 
              fontWeight: 700, 
              fontFamily: 'Poppins', 
              fontStyle: 'normal', 
              color: '#FFF', 
              lineHeight: '55px',
              display: { xs: 'none', sm: 'block' } 
            }}
          >
            Get a partner <br/> invested in your success
          </Typography>
        </Box>
        
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center' 
        }}>
          <Button 
          href='/contact'
            sx={{ 
              color: '#FFF', 
              fontFamily: 'Poppins',
              fontSize: { xs: '16px', sm: '32.766px' }, 
              fontWeight: 600, 
              backgroundColor: '#011B6E', 
              borderRadius: '13.106px', 
              padding: { xs: '10px 30px', sm: '14px 57px' }, 
              '&:hover': {
                backgroundColor: '#011B6E', 
              }
            }}
          >
            CONNECT WITH US
          </Button>
        </Box>
      </Container>
    </section>
  );
};

export default BlueContainer;
