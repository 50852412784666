import { useState } from "react";
import {
  Box,
  Container,
  Stack,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

const ServicesCard = ({ title, description, data }) => {
  const firstServiceId = Object.keys(data)[0];
  const firstService = data[firstServiceId];

  const defaultImage = firstService.image;
  const [expanded, setExpanded] = useState(firstServiceId);
  const [activeImage, setActiveImage] = useState(firstService.image);

  const handleChange = (panel, image) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setActiveImage(isExpanded ? image : defaultImage);
  };

  return (
    <Box
      sx={{
        bgcolor: "white",
        minHeight: "300px",
        display: "flex",
        mt: 2,
        mb: 4,
      }}
    >
      <Container maxWidth="lg">
        <Stack sx={{ alignItems: "flex-start", mb: 2 }}>
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: 2,
              padding: 2,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 700,
                fontSize: { lg: 30, xs: 20 },
                color: "inherit",
                textShadow: 2,
                mt: 3,
                mb: 2,
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: 300,
                fontSize: { lg: 20, xs: 15 },
                color: "inherit",
                textAlign: "start",
                mb: 2,
              }}
            >
              {description}
            </Typography>

            <Card sx={{ display: "flex", mb: 2 }}>
              <CardContent sx={{ flex: 1, width: "50%" }}>
                <Stack>
                  {Object.entries(data).map(([key, service]) => (
                    <Accordion
                      key={service.id}
                      expanded={expanded === service.id}
                      onChange={handleChange(service.id, service.image)}
                    >
                      <AccordionSummary
                        expandIcon={expanded !== service.id ? <AddCircleRoundedIcon /> : ""}
                        aria-controls={`${service.id}-content`}
                        id={`${service.id}-header`}
                      >
                        <Typography variant="h5">{service.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack spacing={2}>
                          <Typography variant="body1">
                            {service.description}
                          </Typography>
                          <a href={`/services/${service.url}`}>
                            {service.url ? <Button variant="contained" color="primary">
                              More
                            </Button> : ""}
                          </a>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Stack>
              </CardContent>

              <CardMedia
                component="img"
                sx={{ width: "50%", height: "auto", objectFit: "cover" }}
                image={activeImage}
                alt={activeImage ? "Service image" : ""}
              />
            </Card>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default ServicesCard;