import React from 'react';
import { Box, Container, Typography, Breadcrumbs, Link, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import EmailContactForm from './EmailContactForm';

const ContactUs = () => {
    return (
        <>
        <Helmet>
          <title>PRSTech.io | Contact Us</title>
          <meta name="description" content="Contact Us PRSTech.io" />
          <meta name="keywords" content="Contact Us PRSTech.io" />
          <meta name="author" content="PRShete Technologies Pvt. Ltd." />
        </Helmet>
        <Container maxWidth="lg" sx={{ mt: 10 }}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">Contact</Typography>
            </Breadcrumbs>
        </Container>
        <Box sx={{ bgcolor: 'white', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', py: 5 }}>
            <Container maxWidth="lg">
                <Typography
                    variant="h1"
                    sx={{
                        fontFamily: 'Montserrat,sans-serif',
                        fontWeight: 900,
                        color: 'inherit',
                        fontSize: { lg: 40, xs: 30 },
                        mt: 1,
                        mb: 4,
                    }}
                >
                    Contact Us
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <EmailContactForm />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontFamily: 'Montserrat,sans-serif',
                                        fontWeight: 600,
                                        color: 'inherit',
                                        fontSize: { lg: 20, xs: 18 },
                                        mb: 1,
                                    }}
                                >
                                    India
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" sx={{ mb: 1 }}>
                                            Pune
                                        </Typography>
                                        <Typography variant="body2" sx={{ mb: 1 }}>
                                            113, Sai Vision, Pimple Saudagar, Pune, India, 411027
                                        </Typography>
                                        <Typography variant="body2" sx={{ mb: 2 }}>
                                            Phone: +91 12345677
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" sx={{ mb: 1 }}>
                                              Pune
                                        </Typography>
                                        <Typography variant="body2" sx={{ mb: 1 }}>
                                            Wing A, Rose E Mehar, Rahatani, Pune, India, 411017
                                        </Typography>
                                        <Typography variant="body2" sx={{ mb: 2 }}>
                                            Phone: +91 12345677
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontFamily: 'Montserrat,sans-serif',
                                        fontWeight: 600,
                                        color: 'inherit',
                                        fontSize: { lg: 20, xs: 18 },
                                        mb: 1,
                                    }}
                                >
                                    Europe
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" sx={{ mb: 1 }}>
                                            Amsterdam
                                        </Typography>
                                        <Typography variant="body2" sx={{ mb: 1 }}>
                                            Hoofdweg, 1058AW, Amsterdam, Noord Holland, The Netherlands, 1019
                                        </Typography>
                                        <Typography variant="body2" sx={{ mb: 2 }}>
                                            Phone: +31 12345677
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        </>
    );
}

export default ContactUs;