import React from "react";
import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

const Resource = ({ blog }) => {
  return (
    <Card
      sx={{
        width: "100%",
        borderRadius: "36px",
        backgroundColor: "#F9F9F9",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Link to={`/resources/${blog.url}`} style={{ textDecoration: "none" }}>
        <CardMedia
          component="img"
          height="225"
          image={blog.image}
          alt={blog.title}
          sx={{
            borderRadius: "32px 32px 32px 32px",
            objectFit: "cover",
            padding: 2,
          }}
        />

        {/* Content Section */}
        <Box sx={{ padding: "16px", bottom: 20, left: 20, right: 20 }}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "18px",
              color: "#000",
              marginBottom: "3px",
              
            }}
          >
            {blog.title}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "14px",
              color: "#363636",
              marginBottom: "16px",
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              WebkitLineClamp: 2, 
              textOverflow: 'ellipsis',
            }}
          >
            {blog.description}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography
              variant="text"
              sx={{
                fontFamily: "Poppins",
                fontWeight: "bold",
                fontSize: "16px",
                color: "#0BA0E0",
                padding: 0,
                mr: 2,
                fontStyle: "normal",
                lineHeight: "normal",
              }}
            >
              Read more
            </Typography>
          </Box>
        </Box>
      </Link>
    </Card>
  );
};

export default Resource;
