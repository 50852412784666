import React from "react";
import { Box, Container, Stack, Typography, Grid, Button } from "@mui/material";
import Resource from "./Resource";

const ResourcesCarousel = ({ data }) => {
  const sliceData = Object.entries(data.sub).slice(0, 3);

  return (
    <Box
      sx={{
        padding: 2,
        marginRight: { xs: 1, sm: 7 },
        marginLeft: { xs: 1, sm: 7 },
      }}
    >
      <Box sx={{ padding: 2 }}>
        <Stack sx={{ alignItems: "center", mb: 2 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: { xs: 700, sm: 600 },
              fontSize: { xs: "26px", sm: "50px" },
              color: "#1D1D1E",
              textShadow: 2,
              lineHeight: "normal",
              fontStyle: "normal",
            }}
          >
            {data.title}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: { xs: "18px", sm: "26px" },
              fontWeight: 400,
              color: "#363636",
              textAlign: "center",
              mb: 2,
            }}
          >
            {data.description}
          </Typography>
        </Stack>

        <Grid container spacing={7.875} direction="row" justifyContent="center">
          {sliceData.map(([key, blog]) => (
            <Grid item xs={12} sm={12} md={4} key={blog.id}>
              <Resource blog={blog} />
            </Grid>
          ))}
        </Grid>

        <Box display="flex" justifyContent="center" p={2}>
          <Button
            href="/resources"
            sx={{
              backgroundColor: "#011B6E",
              marginTop: 5,
              color: "white",
              padding: "14px 84px",
              fontSize: 20,
              fontFamily: "Poppins",
              fontWeight: 600,
              borderRadius: 30,
              "&:hover": {
                backgroundColor: "#019AE5",
              },
            }}
          >
            View All
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ResourcesCarousel;
