import React from 'react';
import { Card, CardContent, CardMedia, CardHeader, CardActions, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Industry = ({ industry }) => {
  return (
    <Card sx={{ marginBottom: 2, boxShadow: 2 }}>
      <CardHeader
        title={industry.title}
        titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
      />
      <CardMedia
        component="img"
        height="150"
        image={industry.image}
        alt={industry.title}
        sx={{ objectFit: 'cover' }}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {industry.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="contained" component={Link} to={industry.url}>
          Explore More
        </Button>
      </CardActions>
    </Card>
  );
};

export default Industry;
